/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import Box from "@mui/material/Box";
import logoBig from "./Image/desktop-logo.png";
import lagEng from "./Image/flag-eng.png";
import lagVn from "./Image/flag-vietnam.png";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import imgMision from "./Image/mision_vision.jpg";
import { Grid } from "@mui/material";
import imgRips from "./Image/74c0ec75e5a421fa78b5.jpg";
import imgSat from "./Image/satin.jpg";
import imgTwill from "./Image/TWILL.jpg";
import imgOxf from "./Image/Oxford.jpg";
import { useMediaQuery } from "react-responsive";

function ProductVNPage() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  let navigate = useNavigate();
  const changLangueVietNam = () => {
    let path = "/";
    navigate(path);
  };
  const changLangueEnglish = () => {
    let path = "/productENG";
    navigate(path);
  };

  const linkToMision = () => {
    let path = "/misionVN";
    navigate(path);
  };
  const linkToMisionEng = () => {
    let path = "/misionENG";
    navigate(path);
  };
  const linkToContact=()=>{
    let path = "/contact-vn";
    navigate(path);
  }
  return (
    <div>
      {isMobile && (
        <>
          <div style={{ display: "flex", padding: "8px" }}>
            <div style={{ flexGrow: 1 }}>
              <a href="/" className="linkLogo-mobile">
                <img src={logoBig} style={{ width: "60px" }} />
                <div style={{ marginTop: "15px" }}>UNITEDASIA.CO</div>
              </a>
            </div>
            <div className="headerLangue">
              <Stack direction="row" spacing={2}>
                <Avatar
                  alt="Remy Sharp"
                  src={lagVn}
                  onClick={changLangueVietNam}
                  style={{ cursor: "pointer", width: 30, height: 30 }}
                />
                <Avatar
                  alt="Remy Sharp"
                  src={lagEng}
                  onClick={changLangueEnglish}
                  style={{ cursor: "pointer", width: 30, height: 30 }}
                />
              </Stack>
            </div>
          </div>
          <div class="headerLangue-mobile">
            <ul>
              <li onClick={changLangueVietNam}>TRANG CHỦ</li>
              <li>SẢN PHẨM</li>
              <li onClick={linkToMision}>SỨ MỆNH VÀ TẦM NHÌN</li>
              <li onClick={linkToContact}>LIÊN HỆ</li>
            </ul>
          </div>
          <hr />
          <div style={{ padding: "10px" }}>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Chúng tôi là nhà máy dệt sợi tổng hợp, với máy móc thiết bị sau:
            </div>
            <div style={{ textAlign: "center", width: "100%" }}>
              <table style={{ fontSize: "20px" }}>
                <tbody>
                  <tr>
                    <td style={{ padding: "10px" }}>Máy se sợi 2/1</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "10px" }}>Máy mắc phân băng</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "10px" }}>Máy mắc trực tiếp</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "10px" }}>Máy hồ sợi</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "10px" }}>Máy dệt nước</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "10px" }}>Máy sấy vải</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ padding: "10px" }}>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Chúng tôi có thể sản xuất nhiều mẫu vải, kiểu dáng và các loại vải
              như:
            </div>
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>CÁC SẢN PHẨM</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>CÁC MẪU VÀ THIẾT KẾ</td>
                  <td>SẢN PHẨM LIÊN QUAN</td>
                </tr>
                <tr>
                  <td rowSpan={5}>PLAIN WEAVE</td>
                </tr>
                <tr>
                  <td>TAFFETA</td>
                </tr>
                <tr>
                  <td>OXFORD</td>
                </tr>
                <tr>
                  <td>CHIFFON / PONGEE</td>
                </tr>
                <tr>
                  <td>FAKE MEMORY</td>
                </tr>
                <tr>
                  <td rowSpan={3}>RIPSTOP</td>
                </tr>
                <tr>
                  <td>RIPSTOP 30D/50D/75D/100D</td>
                </tr>
                <tr>
                  <td>2MM/3MM/5MM</td>
                </tr>
                <tr>
                  <td rowSpan={3}>TWILL</td>
                </tr>
                <tr>
                  <td>SOFT / PEACH SKIN FABRIC</td>
                </tr>
                <tr>
                  <td>DIMI FABRIC</td>
                </tr>
                <tr>
                  <td rowSpan={3}>SATEN</td>
                </tr>
                <tr>
                  <td>VARIUS SATINS</td>
                </tr>
                <tr>
                  <td>DOUBLE SATIN FABRIC</td>
                </tr>
                <tr>
                  <td rowSpan={4}>DOBBY</td>
                </tr>
                <tr>
                  <td>DIAGONAL FABRIC</td>
                </tr>
                <tr>
                  <td>BLACKOUT FABRIC</td>
                </tr>
                <tr>
                  <td>CUSTOM FABRICS</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ textAlign: "center" }}>
            <img src={imgRips} width={350} height={250} />
            <div className="txtTitleProduct">RIPSTOP</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <img src={imgSat} width={350} height={250} />
            <div className="txtTitleProduct">SATEN</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <img src={imgTwill} width={350} height={250} />
            <div className="txtTitleProduct">TWILL</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <img src={imgOxf} width={350} height={250} />
            <div className="txtTitleProduct">OXFORD</div>
          </div>
        </>
      )}
      {isDesktop && (
        <>
          <Box
            sx={{
              maxWidth: "100%",
              flexGrow: 1,
              marginLeft: "8%",
              marginRight: "8%",
              marginTop: "10px",
            }}
          >
            <div style={{ height: "80px", display: "flex" }}>
              <div>
                <a href="/" className="linkLogo">
                  <img src={logoBig} style={{ width: "80px" }} />
                  <div style={{ marginTop: "15px" }}>UNITEDASIA.CO</div>
                </a>
              </div>
              <div className="headerRight">
                <div className="headerLangue">
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Remy Sharp"
                      src={lagVn}
                      onClick={changLangueVietNam}
                      style={{ cursor: "pointer", width: 30, height: 30 }}
                    />
                    <Avatar
                      alt="Remy Sharp"
                      src={lagEng}
                      onClick={changLangueEnglish}
                      style={{ cursor: "pointer", width: 30, height: 30 }}
                    />
                  </Stack>
                </div>
                <div class="clearfix"></div>
                <div class="headerLangue">
                  <ul>
                    <li onClick={changLangueVietNam}>TRANG CHỦ</li>
                    <li>SẢN PHẨM</li>
                    <li onClick={linkToMision}>SỨ MỆNH VÀ TẦM NHÌN</li>
                    <li onClick={linkToContact}>LIÊN HỆ</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginBottom: "20px",
                    }}
                  >
                    Chúng tôi là nhà máy dệt sợi tổng hợp, với máy móc thiết bị
                    sau:
                  </div>
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <table style={{ width: "60%", fontSize: "20px" }}>
                      <tbody>
                        <tr>
                          <td style={{ padding: "10px" }}>Máy se sợi 2/1</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px" }}>Máy mắc phân băng</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px" }}>Máy mắc trực tiếp</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px" }}>Máy hồ sợi</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px" }}>Máy dệt nước</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px" }}>Máy sấy vải</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginBottom: "20px",
                    }}
                  >
                    Chúng tôi có thể sản xuất nhiều mẫu vải, kiểu dáng và các
                    loại vải như:
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>CÁC SẢN PHẨM</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>CÁC MẪU VÀ THIẾT KẾ</td>
                        <td>SẢN PHẨM LIÊN QUAN</td>
                      </tr>
                      <tr>
                        <td rowSpan={5}>PLAIN WEAVE</td>
                      </tr>
                      <tr>
                        <td>TAFFETA</td>
                      </tr>
                      <tr>
                        <td>OXFORD</td>
                      </tr>
                      <tr>
                        <td>CHIFFON / PONGEE</td>
                      </tr>
                      <tr>
                        <td>FAKE MEMORY</td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>RIPSTOP</td>
                      </tr>
                      <tr>
                        <td>RIPSTOP 30D/50D/75D/100D</td>
                      </tr>
                      <tr>
                        <td>2MM/3MM/5MM</td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>TWILL</td>
                      </tr>
                      <tr>
                        <td>SOFT / PEACH SKIN FABRIC</td>
                      </tr>
                      <tr>
                        <td>DIMI FABRIC</td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>SATEN</td>
                      </tr>
                      <tr>
                        <td>VARIUS SATINS</td>
                      </tr>
                      <tr>
                        <td>DOUBLE SATIN FABRIC</td>
                      </tr>
                      <tr>
                        <td rowSpan={4}>DOBBY</td>
                      </tr>
                      <tr>
                        <td>DIAGONAL FABRIC</td>
                      </tr>
                      <tr>
                        <td>BLACKOUT FABRIC</td>
                      </tr>
                      <tr>
                        <td>CUSTOM FABRICS</td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <img src={imgRips} width={250} height={220} />
                  <div className="txtTitleProduct">RIPSTOP</div>
                </Grid>
                <Grid item md={3}>
                  <img src={imgSat} width={250} height={220} />
                  <div className="txtTitleProduct">SATEN</div>
                </Grid>
                <Grid item md={3}>
                  <img src={imgTwill} width={250} height={220} />
                  <div className="txtTitleProduct">TWILL</div>
                </Grid>
                <Grid item md={3}>
                  <img src={imgOxf} width={250} height={220} />
                  <div className="txtTitleProduct">OXFORD</div>
                </Grid>
              </Grid>
            </div>
          </Box>
        </>
      )}
    </div>
  );
}

export default ProductVNPage;
