import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
function CheckMobilePage() {
  const [valueTotalCheck, setValueTotalCheck] = React.useState("");
  const [valueTotalCheckShow, setValueTotalCheckShow] = React.useState("");
  React.useEffect(() => {
    loadingTotalBeam();
    // ref.current.ownerDocument.body.scrollTop = 0;
  }, []);
  const loadingTotalBeam = () => {
    fetch("https://hoangthongtelecom.com/KiemVaiLienA/getTotal.php", {
      method: "POST",
      //body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueTotalCheck(result);
        setValueTotalCheckShow(result);
        console.log(result);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };

  const handleInputChange = (event) => {
    const qr = valueTotalCheck.filter((item) =>
      item.mahang.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setValueTotalCheckShow(qr);
  };
  return (
    <div style={{ backgroundColor: "gray", padding: "5px",overflow:'hidden' }}>
      <div style={{ marginBottom: "5px" }}>
        <TextField
          id="outlined-basic"
          style={{
            backgroundColor: "white",
            width: "100%",
            borderRadius: "10px",
          }}
          placeholder="Enter style to search"
          size="small"
          variant="outlined"
          onChange={handleInputChange}
        />
      </div>
      <div style={{
        height:'82vh',
        overflow:'auto'
      }}>
      {valueTotalCheckShow === "" ? (
        <>
        <Box
            sx={{
              display: "flex",
              backgroundColor: "white",
              width: "100%",
              height:'90vh',
             // paddingTop:'20%',
             // paddingLeft:'30%',
              textAlign: "center",
              alignItems:'center',
              justifyItems:'center',
              justifyContent:'center'
            }}
          >
            <CircularProgress />
            <div>Loading ....</div>
          </Box>
        </>
      ) : (
        valueTotalCheckShow.map((item) => (
          <Card
            sx={{
              minWidth: 275,
              marginBottom: "5px",
              padding: 0,
              overflow: "auto",
              maxHeight:'100%'
            }}
          >
            <CardContent style={{ margin: 0, padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#DAEEF3",
                  padding: 5,
                  alignItems: "center",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                <div style={{ flexGrow: 1 }}>{item.mahang}</div>
                <div style={{ color: "teal" }}>
                  {(
                    ((Number(item.A) + Number(item.X) + Number(item.sumOut)) /
                      (Number(item.sumOut) + Number(item.total))) *
                    100
                  ).toFixed(2) + "%"}
                </div>
              </div>
              {item.style === "1" ? (
                <div>
                  <div style={{ padding: 5, display: "flex" }}>
                    <div style={{ flexGrow: 1, color: "black" }}>X/Roll</div>
                    <div style={{ flexGrow: 1, color: "black" }}>Y/Roll</div>
                    <div style={{ flexGrow: 1, color: "black" }}>Z/Roll</div>
                    <div style={{ color: "black" }}>C/Roll</div>
                  </div>
                  <div style={{ padding: 5, display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        flexGrow: 1,
                      }}
                    >
                      <div>{item.X === "0" ? "-" : item.X}</div>
                      <div style={{ color: "red" }}>/</div>
                      <div>{item.CountX === "0" ? "-" : item.CountX}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexGrow: 1,
                      }}
                    >
                      <div>{item.Y === "0" ? "-" : item.Y}</div>
                      <div style={{ color: "red" }}>/</div>
                      <div>{item.CountY === "0" ? "-" : item.CountY}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexGrow: 1,
                      }}
                    >
                      <div>{item.Z === "0" ? "-" : item.Z}</div>
                      <div style={{ color: "red" }}>/</div>
                      <div>{item.CountZ === "0" ? "-" : item.CountZ}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>{item.C === "0" ? "-" : item.C}</div>
                      <div style={{ color: "red" }}>/</div>
                      <div>{item.CountC === "0" ? "-" : item.CountC}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div style={{ padding: 5, display: "flex" }}>
                    <div style={{ flexGrow: 1, color: "black" }}>A/Roll</div>
                    <div style={{ flexGrow: 1, color: "black" }}>B/Roll</div>
                    <div style={{ color: "black" }}>C/Roll</div>
                  </div>
                </div>
              )}

              <hr style={{ margin: 8 }}></hr>

              <div style={{ padding: 5, display: "flex" }}>
                <div style={{ flexGrow: 1, color: "red", fontWeight: "bold" }}>
                  Total Inventory
                </div>
                <div
                  style={{
                    flexGrow: 1,
                    color: "red",
                    fontWeight: "bold",
                    marginLeft: "10%",
                  }}
                >
                  Total OutPut
                </div>
              </div>
              <div style={{ padding: 5, display: "flex" }}>
                <div style={{ width: "40%", display: "flex" }}>
                  <div
                    style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                  >
                    Meters
                  </div>
                  <div style={{ color: "black", fontWeight: "bold" }}>Row</div>
                </div>
                <div style={{ width: "19%" }}></div>
                <div style={{ width: "40%", display: "flex" }}>
                  <div
                    style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                  >
                    Meters
                  </div>
                  <div style={{ color: "black", fontWeight: "bold" }}>Row</div>
                </div>
              </div>
              <div style={{ padding: 5, display: "flex" }}>
                <div style={{ width: "40%", display: "flex" }}>
                  <div
                    style={{ flexGrow: 1, color: "blue", fontWeight: "bold" }}
                  >
                    {item.total}
                  </div>
                  <div style={{ color: "blue", fontWeight: "bold" }}>
                    {item.totalRoll}
                  </div>
                </div>
                <div style={{ width: "19%" }}></div>
                <div style={{ width: "40%", display: "flex" }}>
                  <div
                    style={{ flexGrow: 1, color: "blue", fontWeight: "bold" }}
                  >
                    {item.sumOut}
                  </div>
                  <div style={{ color: "blue", fontWeight: "bold" }}>
                    {item.totalRollOut}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))
      )}
      </div>
      
    </div>
  );
}

export default CheckMobilePage;
