/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import logoBig from "./Image/desktop-logo.png";
import lagEng from "./Image/flag-eng.png";
import lagVn from "./Image/flag-vietnam.png";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import bando from "./Image/bando.png";
import logoGobal from "./Image/Global.jpg";
import { useMediaQuery } from "react-responsive";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    imgPath: "http://unitedasia.co/Image/maydet_n1.jpg",
  },
  {
    label: "Bird",
    imgPath:
      "http://unitedasia.co/Image/MayHoSoi_auto_x2_colored_toned_light_ai.jpg",
  },
  {
    label: "Bali, Indonesia",
    imgPath:
      "http://unitedasia.co/Image/slide2_auto_x2_colored_toned_light_ai1.jpg",
  },
  {
    label: "Goč, Serbia",
    imgPath: "http://unitedasia.co/Image/mg1.png",
  },
];

function MyComponentPage() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  let navigate = useNavigate();
  const changLangueVietNam = () => {
    let path = "/";
    navigate(path);
  };
  const changLangueEnglish = () => {
    let path = "/homeEng";
    navigate(path);
  };

  const linkToPage = (link) => {
    let path = link;
    navigate(path);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      {isMobile && (
        <>
          <div style={{ display: "flex", padding: "8px" }}>
            <div style={{ flexGrow: 1 }}>
              <a href="/" className="linkLogo-mobile">
                <img src={logoBig} style={{ width: "60px" }} />
                <div style={{ marginTop: "15px" }}>UNITEDASIA.CO</div>
              </a>
            </div>
            <div className="headerLangue">
              <Stack direction="row" spacing={2}>
                <Avatar
                  alt="Remy Sharp"
                  src={lagVn}
                  onClick={changLangueVietNam}
                  style={{ cursor: "pointer", width: 30, height: 30 }}
                />
                <Avatar
                  alt="Remy Sharp"
                  src={lagEng}
                  onClick={changLangueEnglish}
                  style={{ cursor: "pointer", width: 30, height: 30 }}
                />
              </Stack>
            </div>
          </div>
          <div class="headerLangue-mobile">
            <ul>
              <li onClick={changLangueVietNam}>TRANG CHỦ</li>
              <li
                onClick={() => {
                  linkToPage("productVN");
                }}
              >
                SẢN PHẨM
              </li>
              <li
                onClick={() => {
                  linkToPage("misionVN");
                }}
              >
                SỨ MỆNH VÀ TẦM NHÌN
              </li>
              <li  onClick={() => {
                  linkToPage("contact-vn");
                }}>LIÊN HỆ</li>
            </ul>
          </div>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    sx={{
                      height: 255,
                      display: "block",
                      maxWidth: "100%",
                      overflow: "hidden",
                      width: "100%",
                    }}
                    src={step.imgPath}
                    alt={step.label}
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            style={{ marginTop: "-150px" }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
               
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                
              </Button>
            }
          />
          <div className="textAbout-mobile" >VỀ LIÊN Á</div>
            <div style={{ marginTop: "30px",marginLeft:'5px',marginRight:'5px' }}>
              <div className="txtAbout">
                Công ty TNHH Liên Á được thành lập năm 2011. Chúng tôi là một
                nhà máy dệt chuyên sản xuất vải dệt từ sợi tổng hợp. Chúng tôi
                hợp tác chặt chẽ với các đối tác kinh doanh và không ngừng cải
                thiện bản thân.
              </div>
              <div className="txtAbout">
                Vải của chúng tôi được sử dụng trong nhiều loại sản phẩm và lĩnh
                vực khác nhau, chẳng hạn như: hàng may mặc (chủ yếu là áo khoác
                , quần áo thể thao), giày dép, gia dụng và ngành quảng cáo.
              </div>
              <div className="txtAbout">
                Thị trường chính của chúng tôi là Châu Âu (chủ yếu là Thổ Nhĩ
                Kỳ), Nhật Bản và các nước khác.
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <img src={bando} style={{ width: "100%" }} />
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                marginTop: "20px",
              }}
            >
              Global Recycled Standard
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img src={logoGobal} style={{ width: "227px" }} />
            </div>
        </>
      )}
      {isDesktop && (
        <>
          <Box
            sx={{
              maxWidth: "100%",
              flexGrow: 1,
              marginLeft: "8%",
              marginRight: "8%",
              marginTop: "10px",
            }}
          >
            <div style={{ height: "90px", display: "flex" }}>
              <div>
                <a href="/" className="linkLogo">
                  <img src={logoBig} style={{ width: "80px" }} />
                  <div style={{ marginTop: "15px" }}>UNITEDASIA.CO</div>
                </a>
              </div>
              <div className="headerRight">
                <div className="headerLangue">
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Remy Sharp"
                      src={lagVn}
                      onClick={changLangueVietNam}
                      style={{ cursor: "pointer", width: 30, height: 30 }}
                    />
                    <Avatar
                      alt="Remy Sharp"
                      src={lagEng}
                      onClick={changLangueEnglish}
                      style={{ cursor: "pointer", width: 30, height: 30 }}
                    />
                  </Stack>
                </div>
                <div class="clearfix"></div>
                <div class="headerLangue">
                  <ul>
                    <li onClick={changLangueVietNam}>TRANG CHỦ</li>
                    <li
                      onClick={() => {
                        linkToPage("productVN");
                      }}
                    >
                      SẢN PHẨM
                    </li>
                    <li
                      onClick={() => {
                        linkToPage("misionVN");
                      }}
                    >
                      SỨ MỆNH VÀ TẦM NHÌN
                    </li>
                    <li  onClick={() => {
                  linkToPage("contact-vn");
                }}>LIÊN HỆ</li>
                  </ul>
                </div>
              </div>
            </div>
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {images.map((step, index) => (
                <div key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      component="img"
                      sx={{
                        height: 455,
                        display: "block",
                        maxWidth: "100%",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      src={step.imgPath}
                      alt={step.label}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              style={{ marginTop: "-250px" }}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
            <div className="textAbout">VỀ LIÊN Á</div>
            <div style={{ marginTop: "30px" }}>
              <div className="txtAbout">
                Công ty TNHH Liên Á được thành lập năm 2011. Chúng tôi là một
                nhà máy dệt chuyên sản xuất vải dệt từ sợi tổng hợp. Chúng tôi
                hợp tác chặt chẽ với các đối tác kinh doanh và không ngừng cải
                thiện bản thân.
              </div>
              <div className="txtAbout">
                Vải của chúng tôi được sử dụng trong nhiều loại sản phẩm và lĩnh
                vực khác nhau, chẳng hạn như: hàng may mặc (chủ yếu là áo khoác
                , quần áo thể thao), giày dép, gia dụng và ngành quảng cáo.
              </div>
              <div className="txtAbout">
                Thị trường chính của chúng tôi là Châu Âu (chủ yếu là Thổ Nhĩ
                Kỳ), Nhật Bản và các nước khác.
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <img src={bando} style={{ width: "700px" }} />
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                marginTop: "20px",
              }}
            >
              Global Recycled Standard
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img src={logoGobal} style={{ width: "227px" }} />
            </div>
          </Box>
        </>
      )}
    </div>
  );
}
MyComponentPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: MyComponentPage.func,
};
export default MyComponentPage;
