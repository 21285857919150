import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function MachinesPage() {
  const [valueMachines, setValueMachines] = React.useState("");
  React.useEffect(() => {
    loadingTotalBeam();
    // ref.current.ownerDocument.body.scrollTop = 0;
  }, []);
  const loadingTotalBeam = () => {
    const dataBodyCustomer = {
      finish: "0",
    };
    fetch("https://hoangthongtelecom.com/ConnectDB/getMachines.php", {
      method: "POST",
      body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueMachines(result);
        console.log(result);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  return (
    <div>
      {valueMachines === "" ? (
        <div><Box
        sx={{
          display: "flex",
          backgroundColor: "white",
          width: "100%",
          height:'90vh',
         // paddingTop:'20%',
         // paddingLeft:'30%',
          textAlign: "center",
          alignItems:'center',
          justifyItems:'center',
          justifyContent:'center'
        }}
      >
        <CircularProgress />
        <div>Loading ....</div>
      </Box></div>
      ) : (
        valueMachines.map((item) => (
          <Card sx={{ minWidth: 275, marginBottom: "5px", padding: 0 }}>
            <CardContent style={{ margin: 0, padding: 0 }}>
              <div style={{ display: "flex", padding: 10 , alignItems:'center' }}>
                <div
                  style={{
                    width: 70,
                    height: 70,
                    backgroundColor:
                      item.machinesRuntime === "Run time" ? "green" : "red",
                  }}
                ></div>
                <div style={{ paddingLeft: "15px" }}>
                  <div style={{color:'blue', fontWeight:900 , fontSize:18 ,marginBottom:3}}>Machines : {item.machinescode}</div>
                  <div style={{fontSize:17 ,marginBottom:3}}>Style : {item.machinesmahang}</div>
                  <div style={{fontSize:17 ,marginBottom:3}}>Cloth lenght : {item.machinescloth}</div>
                  <div style={{fontSize:17 ,marginBottom:3}}>Efficiency(Current Shift) : {item.machinestoday}</div>
                  <div style={{fontSize:17 ,marginBottom:3}}>Remaining lenght : {item.machinesRemaining}</div>
                  <div style={{fontSize:17 ,marginBottom:3}}>Beam lenght : {item.machinesBeam}</div>
                  <div style={{fontSize:17 ,marginBottom:3}}>Rpm : {item.machinesRpm}</div>
                  <div style={{fontSize:17 }}>Beam name : {item.machine_BeamName}</div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))
      )}
    </div>
  );
}
export default MachinesPage;
