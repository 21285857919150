/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import Box from "@mui/material/Box";
import logoBig from "./Image/desktop-logo.png";
import lagEng from "./Image/flag-eng.png";
import lagVn from "./Image/flag-vietnam.png";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import imgMision from "./Image/mision_vision.jpg";
import { useMediaQuery } from "react-responsive";

function MisionEngPage() {
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const changLangueVietNam = () => {
    let path = "/";
    navigate(path);
  };
  const changLangueEnglish = () => {
    let path = "/misionVN";
    navigate(path);
  };

  const linkToMision = () => {
    let path = "/misionVN";
    navigate(path);
  };
  const linkToMisionEng = () => {
    let path = "/misionENG";
    navigate(path);
  };
  const linkProductEng = () => {
    let path = "/productENG";
    navigate(path);
  };
  const linkHomeEng =()=>{
    let path = "/homeEng";
    navigate(path);
  }
  const linkToContact=()=>{
    let path = "/contact-eng";
    navigate(path);
  }
  return (
    <div>
      {isMobile && (
        <>
          <div style={{ display: "flex", padding: "8px" }}>
            <div style={{ flexGrow: 1 }}>
              <a href="/" className="linkLogo-mobile">
                <img src={logoBig} style={{ width: "60px" }} />
                <div style={{ marginTop: "15px" }}>UNITEDASIA.CO</div>
              </a>
            </div>
            <div className="headerLangue">
              <Stack direction="row" spacing={2}>
                <Avatar
                  alt="Remy Sharp"
                  src={lagVn}
                  onClick={linkToMision}
                  style={{ cursor: "pointer", width: 30, height: 30 }}
                />
                <Avatar
                  alt="Remy Sharp"
                  src={lagEng}
                  onClick={changLangueEnglish}
                  style={{ cursor: "pointer", width: 30, height: 30 }}
                />
              </Stack>
            </div>
          </div>
          <div class="headerLangue-mobile">
            <ul>
              <li style={{width:'18%',fontSize:'13px'}} onClick={linkHomeEng}>HOME</li>
              <li style={{width:'18%',fontSize:'13px'}} onClick={linkProductEng}>PRODUCT</li>
              <li style={{width:'43%',fontSize:'13px'}} onClick={linkToMisionEng}>MISSION AND VISION</li>
              <li style={{width:'20%',fontSize:'13px'}} onClick={linkToContact}>CONTACT</li>
            </ul>
          </div>
          <hr />
          <div style={{ padding: "8px" }}>
            <div
              style={{
                fontSize: "20px",
                fontFamily: "Times New Roman",
                fontWeight: "bold",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              MISSION AND VISION
            </div>
            <div style={{ textAlign: "center" }}>
              <img src={imgMision} width={450} />
            </div>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "30px",
                fontFamily: "Times New Roman",
                fontSize: "20px",
              }}
            >
              <div>
              - Our priority is adding value to the work we do, the business environment we work in and the society we live in.
              </div>
              <div>
              - We believe sustainability starts in business practices, with the management team in the factory floor to focus on flexible and agile production we minimize loss of time, energy and material. While we serve customer needs and focus on today's solutions; we continuously renew, integrate, invest in new and modern machinery; update our practices with the aim to be able to serve the needs of tomorrow in a continuously evolving world.
              </div>
              <div>
              - Our target is to be the reliable, exemplary and advanced weaving mill and a business partner. Where our partners not just benefit but also enjoy working with us in order to sustainably grow as well as confidently invest together.
              </div>
            </div>
          </div>
        </>
      )}
      {isDesktop && (
        <>
          <Box
            sx={{
              maxWidth: "100%",
              flexGrow: 1,
              marginLeft: "8%",
              marginRight: "8%",
              marginTop: "10px",
            }}
          >
            <div style={{ height: "80px", display: "flex" }}>
              <div>
                <a href="/" className="linkLogo">
                  <img src={logoBig} style={{ width: "80px" }} />
                  <div style={{ marginTop: "15px" }}>UNITEDASIA.CO</div>
                </a>
              </div>
              <div className="headerRight">
                <div className="headerLangue">
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Remy Sharp"
                      src={lagVn}
                      onClick={linkToMision}
                      style={{ cursor: "pointer", width: 30, height: 30 }}
                    />
                    <Avatar
                      alt="Remy Sharp"
                      src={lagEng}
                      onClick={linkToMisionEng}
                      style={{ cursor: "pointer", width: 30, height: 30 }}
                    />
                  </Stack>
                </div>
                <div class="clearfix"></div>
                <div class="headerLangue">
                  <ul>
                    <li onClick={linkHomeEng}>HOME</li>
                    <li onClick={linkProductEng}>PRODUCT</li>
                    <li onClick={linkToMisionEng}>MISSION AND VISION</li>
                    <li onClick={linkToContact}>CONTACT</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                fontSize: "20px",
                fontFamily: "Times New Roman",
                fontWeight: "bold",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
             MISSION AND VISION
            </div>
            <div style={{ textAlign: "center" }}>
              <img src={imgMision} width={450} />
            </div>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "30px",
                fontFamily: "Times New Roman",
                fontSize: "20px",
              }}
            >
              <div>
              - Our priority is adding value to the work we do, the business environment we work in and the society we live in.
              </div>
              <div>
              - We believe sustainability starts in business practices, with the management team in the factory floor to focus on flexible and agile production we minimize loss of time, energy and material. While we serve customer needs and focus on today's solutions; we continuously renew, integrate, invest in new and modern machinery; update our practices with the aim to be able to serve the needs of tomorrow in a continuously evolving world.
              </div>
              <div>
              - Our target is to be the reliable, exemplary and advanced weaving mill and a business partner. Where our partners not just benefit but also enjoy working with us in order to sustainably grow as well as confidently invest together.
              </div>
            </div>
          </Box>
        </>
      )}
    </div>
  );
}
export default MisionEngPage;
