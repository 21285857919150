import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewCozyIcon from "@mui/icons-material/ViewCozy";
import { styled } from "@mui/material/styles";
import BeamPage from "./Beam";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import Paper from "@mui/material/Paper";
import ArchiveIcon from "@mui/icons-material/Archive";
import BeamMobilePage from "./Beam-Mobile";
import CheckMobilePage from "./CheckRoll-Mobile";
import SoiMobilePage from "./Soi-Mobile";
import MachinesPage from "./Machines-Mobile";
import MachinesDesktopPage from "./Machines";
import SoiPage from "./Soi";
import CheckRollPage from "./CheckRoll";
import OrderPage from "./Order";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import OrderMobilePage from "./Order-Mobile";

const drawerWidth = 180;

function AdminPage() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [selectedItem, setSelectedItem] = useState("Item 1");
  const handleItemClick = (item) => {
    setSelectedItem(item);
  };
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "center",
    fontSize: "26px",
    fontWeight: "bold",
    fontFamily: "serif",
    color: "blue",
  }));
  const [valueTab, setValueTab] = React.useState(0);
  const ref = React.useRef(null);

  React.useEffect(() => {
    // ref.current.ownerDocument.body.scrollTop = 0;
  }, []);
  return (
    <div>
      {isMobile && (
        <>
          <Box sx={{ pb: 7 }} ref={ref}>
            <CssBaseline />
          {valueTab ===0 &&<BeamMobilePage/>}
          {valueTab ===1 &&<CheckMobilePage/>}
          {valueTab ===2 &&<OrderMobilePage/>}
          {valueTab ===3 &&<SoiMobilePage/>}
          {
            valueTab ===4 && <MachinesPage/>
          }
            <Paper
              sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
              elevation={3}
            >
              <BottomNavigation
                showLabels
                value={valueTab}
                onChange={(event, newValue) => {
                  setValueTab(newValue);
                }}
              >
                <BottomNavigationAction
                  label="Beam"
                  icon={<RestoreIcon />}
                />
                <BottomNavigationAction
                  label="Kiểm vải"
                  icon={<LibraryAddCheckIcon />}
                />
                <BottomNavigationAction
                  label="Order"
                  icon={<LocalMallIcon />}
                />
                <BottomNavigationAction
                  label="Sợi"
                  icon={<ArchiveIcon />}
                />
                <BottomNavigationAction
                  label="Machines"
                  icon={<ConnectedTvIcon />}
                />
              </BottomNavigation>
            
            </Paper>
            
          </Box>
         
        </>
      )}
      {isDesktop && (
        <>
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <DrawerHeader style={{fontSize:28,fontWeight:800}}>United asia</DrawerHeader>
            {/* <Toolbar /> */}
            <Box sx={{ overflow: "auto" }}>
              <nav aria-label="main mailbox folders">
                <List>
                  <ListItem
                    button
                    onClick={() => handleItemClick("Item 1")}
                    disablePadding
                    sx={{
                      backgroundColor:
                        selectedItem === "Item 1" ? "grey" : "white",
                      color: selectedItem === "Item 1" ? "white" : "black",
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary="Beam" />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{
                      backgroundColor:
                        selectedItem === "Item 2" ? "grey" : "white",
                      color: selectedItem === "Item 2" ? "white" : "black",
                    }}
                    onClick={() => handleItemClick("Item 2")}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <LibraryAddCheckIcon />
                      </ListItemIcon>
                      <ListItemText primary="Kiểm vải" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{
                      backgroundColor:
                        selectedItem === "Item 3" ? "grey" : "white",
                      color: selectedItem === "Item 3" ? "white" : "black",
                    }}
                    onClick={() => handleItemClick("Item 3")}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <LocalMallIcon />
                      </ListItemIcon>
                      <ListItemText primary="Order" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{
                      backgroundColor:
                        selectedItem === "Item 4" ? "grey" : "white",
                      color: selectedItem === "Item 4" ? "white" : "black",
                    }}
                    onClick={() => handleItemClick("Item 4")}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <ViewCozyIcon />
                      </ListItemIcon>
                      <ListItemText primary="Yarn " />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{
                      backgroundColor:
                        selectedItem === "Item 5" ? "grey" : "white",
                      color: selectedItem === "Item 5" ? "white" : "black",
                    }}
                    onClick={() => handleItemClick("Item 5")}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <ConnectedTvIcon />
                      </ListItemIcon>
                      <ListItemText primary="Machines" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </nav>
            </Box>
          </Drawer>
        </>
      )}
      {selectedItem === "Item 1" && <BeamPage />}
      {selectedItem === "Item 2" && <CheckRollPage />}
      {selectedItem === "Item 3" && <OrderPage />}
      {selectedItem === "Item 4" && <SoiPage />}
      {selectedItem === "Item 5" && <MachinesDesktopPage />}
    </div>
  );
}

export default AdminPage;
