
import './App.css';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route  } from "react-router-dom";
import MyComponentPage from './MyComponent';
import MyHomeEngPage from './MyHomeEng';
import MisionVNPage from './MisionVN';
import MisionEngPage from './MisionEng';
import ProductVNPage from './ProductVN';
import ProductENGPage from './ProductENG';
import AboutVNPage from './About';
import ContactENGPage from './ContactEng';
import AdminPage from './Admin/Admin';
import BeamDetailPage from './Admin/Beam-Detail';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>      
          <Route exact path="/" element={<MyComponentPage/>} />
          <Route exact path="/homeEng" element={<MyHomeEngPage/>} />
          <Route exact path="/productVN" element={<ProductVNPage/>} />
          <Route exact path="/productENG" element={<ProductENGPage/>} />
          <Route exact path="/misionVN" element={<MisionVNPage/>} />
          <Route exact path="/misionENG" element={<MisionEngPage/>} />
          <Route exact path="/contact-vn" element={<AboutVNPage/>} />
          <Route exact path="/contact-eng" element={<ContactENGPage/>} />
          <Route exact path="/admin" element={<AdminPage/>} />  
          <Route exact path="/beam-detail" element={<BeamDetailPage/>} />       
          <Route component={<MyComponentPage/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
