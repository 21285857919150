/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import Box from "@mui/material/Box";
import logoBig from "./Image/desktop-logo.png";
import lagEng from "./Image/flag-eng.png";
import lagVn from "./Image/flag-vietnam.png";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import imgMision from "./Image/mision_vision.jpg";
import { useMediaQuery } from "react-responsive";

function MisionVNPage() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  let navigate = useNavigate();
  const changLangueVietNam = () => {
    let path = "/";
    navigate(path);
  };
  const changLangueEnglish = () => {
    let path = "/homeEng";
    navigate(path);
  };

  const linkToMision = () => {
    let path = "/misionVN";
    navigate(path);
  };
  const linkToMisionEng = () => {
    let path = "/misionENG";
    navigate(path);
  };

  const linkProductVN=()=>{
    let path = "/productVN";
    navigate(path);
  }

  const linkToContact=()=>{
    let path = "/contact-vn";
    navigate(path);
  }

  return (
    <div>
      {isMobile && (
        <>
          <div style={{ display: "flex", padding: "8px" }}>
            <div style={{ flexGrow: 1 }}>
              <a href="/" className="linkLogo-mobile">
                <img src={logoBig} style={{ width: "60px" }} />
                <div style={{ marginTop: "15px" }}>UNITEDASIA.CO</div>
              </a>
            </div>
            <div className="headerLangue">
              <Stack direction="row" spacing={2}>
                <Avatar
                  alt="Remy Sharp"
                  src={lagVn}
                  onClick={changLangueVietNam}
                  style={{ cursor: "pointer", width: 30, height: 30 }}
                />
                <Avatar
                  alt="Remy Sharp"
                  src={lagEng}
                  onClick={changLangueEnglish}
                  style={{ cursor: "pointer", width: 30, height: 30 }}
                />
              </Stack>
            </div>
          </div>
          <div class="headerLangue-mobile">
            <ul>
              <li onClick={changLangueVietNam}>TRANG CHỦ</li>
              <li onClick={linkProductVN}>SẢN PHẨM</li>
              <li onClick={linkToMision}>SỨ MỆNH VÀ TẦM NHÌN</li>
              <li onClick={linkToContact}>LIÊN HỆ</li>
            </ul>
          </div>
          <hr />
          <div style={{ padding: "8px" }}>
            <div
              style={{
                fontSize: "20px",
                fontFamily: "Times New Roman",
                fontWeight: "bold",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              TẦM NHÌN VÀ SỨ MỆNH
            </div>
            <div style={{ textAlign: "center" }}>
              <img src={imgMision} width={450} />
            </div>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "30px",
                fontFamily: "Times New Roman",
                fontSize: "20px",
              }}
            >
              <div>
                - Ưu tiên của chúng tôi là gia tăng giá trị cho công việc chúng
                tôi làm, môi trường kinh doanh chúng tôi làm việc và xã hội
                chúng tôi đang sống.
              </div>
              <div>
                -Chúng tôi tin rằng tính bền vững bắt đầu từ thực tiễn kinh
                doanh, với đội ngũ quản lý trong nhà máy tập trung vào sản xuất
                linh hoạt và nhanh nhẹn, chúng tôi giảm thiểu tổn thất thời
                gian, năng lượng và nguyên liệu. rong khi chúng tôi phục vụ nhu
                cầu của khách hàng và tập trung vào các giải pháp ngày nay;
                chúng tôi liên tục đổi mới, hội nhập, đầu tư máy móc mới, hiện
                đại; cập nhật các hoạt động của chúng tôi với mục đích có thể
                phục vụ nhu cầu của ngày mai trong một thế giới liên tục phát
                triển.
              </div>
              <div>
                - Mục tiêu của chúng tôi là trở thành nhà máy dệt tiên tiến,
                đáng tin cậy và là đối tác kinh doanh. Nơi các đối tác của chúng
                tôi không chỉ được hưởng lợi mà còn thích hợp tác với chúng tôi
                để phát triển bền vững cũng như tự tin đầu tư cùng nhau.
              </div>
            </div>
          </div>
        </>
      )}
      {isDesktop && (
        <>
          <Box
            sx={{
              maxWidth: "100%",
              flexGrow: 1,
              marginLeft: "8%",
              marginRight: "8%",
              marginTop: "10px",
            }}
          >
            <div style={{ height: "80px", display: "flex" }}>
              <div>
                <a href="/" className="linkLogo">
                  <img src={logoBig} style={{ width: "80px" }} />
                  <div style={{ marginTop: "15px" }}>UNITEDASIA.CO</div>
                </a>
              </div>
              <div className="headerRight">
                <div className="headerLangue">
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Remy Sharp"
                      src={lagVn}
                      onClick={changLangueVietNam}
                      style={{ cursor: "pointer", width: 30, height: 30 }}
                    />
                    <Avatar
                      alt="Remy Sharp"
                      src={lagEng}
                      onClick={linkToMisionEng}
                      style={{ cursor: "pointer", width: 30, height: 30 }}
                    />
                  </Stack>
                </div>
                <div class="clearfix"></div>
                <div class="headerLangue">
                  <ul>
                    <li onClick={changLangueVietNam}>TRANG CHỦ</li>
                    <li  onClick={linkProductVN}>SẢN PHẨM</li>
                    <li onClick={linkToMision}>SỨ MỆNH VÀ TẦM NHÌN</li>
                    <li onClick={linkToContact}>LIÊN HỆ</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                fontSize: "20px",
                fontFamily: "Times New Roman",
                fontWeight: "bold",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              TẦM NHÌN VÀ SỨ MỆNH
            </div>
            <div style={{ textAlign: "center" }}>
              <img src={imgMision} width={450} />
            </div>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "30px",
                fontFamily: "Times New Roman",
                fontSize: "20px",
              }}
            >
              <div>
                - Ưu tiên của chúng tôi là gia tăng giá trị cho công việc chúng
                tôi làm, môi trường kinh doanh chúng tôi làm việc và xã hội
                chúng tôi đang sống.
              </div>
              <div>
                -Chúng tôi tin rằng tính bền vững bắt đầu từ thực tiễn kinh
                doanh, với đội ngũ quản lý trong nhà máy tập trung vào sản xuất
                linh hoạt và nhanh nhẹn, chúng tôi giảm thiểu tổn thất thời
                gian, năng lượng và nguyên liệu. rong khi chúng tôi phục vụ nhu
                cầu của khách hàng và tập trung vào các giải pháp ngày nay;
                chúng tôi liên tục đổi mới, hội nhập, đầu tư máy móc mới, hiện
                đại; cập nhật các hoạt động của chúng tôi với mục đích có thể
                phục vụ nhu cầu của ngày mai trong một thế giới liên tục phát
                triển.
              </div>
              <div>
                - Mục tiêu của chúng tôi là trở thành nhà máy dệt tiên tiến,
                đáng tin cậy và là đối tác kinh doanh. Nơi các đối tác của chúng
                tôi không chỉ được hưởng lợi mà còn thích hợp tác với chúng tôi
                để phát triển bền vững cũng như tự tin đầu tư cùng nhau.
              </div>
            </div>
          </Box>
        </>
      )}
    </div>
  );
}
export default MisionVNPage;
