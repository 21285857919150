
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import OutputIcon from '@mui/icons-material/Output';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

function BeamDetailPage(){
    const ref = React.useRef(null);
    const [valueTab, setValueTab] = React.useState(0);
    return(
        <div>
            <Box sx={{ pb: 7 }} ref={ref}>
            <CssBaseline />
          {valueTab ===0 &&<></>}
          {valueTab ===1 &&<></>}
          {valueTab ===2 &&<></>}
          
            <Paper
              sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
              elevation={3}
            >
              <BottomNavigation
                showLabels
                value={valueTab}
                onChange={(event, newValue) => {
                  setValueTab(newValue);
                }}
              >
                <BottomNavigationAction
                  label="Input"
                  icon={<ControlPointIcon />}
                />
                <BottomNavigationAction
                  label="Output"
                  icon={<OutputIcon />}
                />
                <BottomNavigationAction
                  label="Finish"
                  icon={<AssignmentTurnedInIcon />}
                />
                
              </BottomNavigation>
            
            </Paper>
            
          </Box>
        </div>
    );
}
export default BeamDetailPage;
