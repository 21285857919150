import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

function BeamMobilePage() {
  const [valueTotalBeam, setValueTotalBeam] = React.useState("");
  const [valueTotalBeamShow, setValueTotalBeamShow] = React.useState("");
  const navigate = useNavigate();
  React.useEffect(() => {
     loadingTotalBeam();
    // ref.current.ownerDocument.body.scrollTop = 0;
  }, []);
  const loadingTotalBeam = () => {
    const dataBodyCustomer = {
      finish: "0",
    };
    fetch("https://hoangthongtelecom.com/QLySoi/getTotalBeam.php", {
      method: "POST",
      body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueTotalBeam(result);
        setValueTotalBeamShow(result);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  const handleInputChange = (event) => {
    const qr = valueTotalBeam.filter((item) =>
      item.style.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setValueTotalBeamShow(qr);
  };
  return (
    <div style={{ backgroundColor: "gray", padding: "5px" }}>
      <div style={{ marginBottom: "5px" }}>
        <TextField
          id="outlined-basic"
          style={{
            backgroundColor: "white",
            width: "100%",
            borderRadius: "10px",
          }}
          placeholder="Enter style to search"
          size="small"
          variant="outlined"
          onChange={handleInputChange}
        />
      </div>
      {valueTotalBeamShow === "" ? (
        <>
          <Box
            sx={{
              display: "flex",
              backgroundColor: "white",
              width: "100%",
              height:'90vh',
             // paddingTop:'20%',
             // paddingLeft:'30%',
              textAlign: "center",
              alignItems:'center',
              justifyItems:'center',
              justifyContent:'center'
            }}
          >
            <CircularProgress />
            <div>Loading ....</div>
          </Box>
        </>
      ) : (
        valueTotalBeamShow.map((item) => (
          <Card sx={{ minWidth: 275, marginBottom: "5px", padding: 0 }}>
            <CardContent style={{ margin: 0, padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#DAEEF3",
                  padding: 5,
                  alignItems: "center",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                <div style={{ flexGrow: 1 }}>{item.style}</div>
                <div style={{ flexGrow: 1 }}>{item.orderNumber}</div>
                {/* <Button style={{ backgroundColor: "teal", color: "black" }} onClick={navigate("/beam-detail")}>
                  Detail
                </Button> */}
              </div>
              <div style={{ padding: 5, display: "flex" }}>
                <div style={{ flexGrow: 1, color: "red" }}>Loom</div>
                <div style={{ flexGrow: 1, color: "red" }}>Input</div>
                <div style={{ flexGrow: 1, color: "red" }}>Output</div>
                <div style={{ color: "red" }}>Inventory</div>
              </div>
              <div style={{ padding: 5, display: "flex" }}>
                <div
                  style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                >
                  200
                </div>
                <div
                  style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                >
                  {item.input200}
                </div>
                <div
                  style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                >
                  {item.output200}
                </div>
                <div style={{ color: "black", fontWeight: "bold" }}>
                  {item.Ton200}
                </div>
              </div>
              <hr></hr>
              <div style={{ padding: 5, display: "flex" }}>
                <div
                  style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                >
                  300
                </div>
                <div
                  style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                >
                  {item.input300}
                </div>
                <div
                  style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                >
                  {item.output300}
                </div>
                <div style={{ color: "black", fontWeight: "bold" }}>
                  {item.Ton300}
                </div>
              </div>
              <hr></hr>
              <div style={{ padding: 5, display: "flex" }}>
                <div
                  style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                >
                  400
                </div>
                <div
                  style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                >
                  {item.input400}
                </div>
                <div
                  style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                >
                  {item.output400}
                </div>
                <div style={{ color: "black", fontWeight: "bold" }}>
                  {item.Ton400}
                </div>
              </div>
              <hr></hr>
              <div style={{ padding: 5, display: "flex" }}>
                <div
                  style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                >
                  Total
                </div>
                <div
                  style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                >
                  {item.totalInput}
                </div>
                <div
                  style={{ flexGrow: 1, color: "black", fontWeight: "bold" }}
                >
                  {item.totaloutput}
                </div>
                <div style={{ color: "black", fontWeight: "bold" }}>
                  {item.TonTotal}
                </div>
              </div>
            </CardContent>
          </Card>
        ))
      )}
    </div>
  );
}

export default BeamMobilePage;
