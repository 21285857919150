import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor:"#DDD9C4",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function BeamPage() {
 // const theme = useTheme();
  const [value, setValue] = React.useState("1");
  const [valueTotalBeam, setValueTotalBeam] = React.useState("");
  const [valueInputBeam, setValueInputBeam] = React.useState("");
  const [valueOutBeam, setValueOutBeam] = React.useState("");
  const [valueFinishBeam, setValueFinishBeam] = React.useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  useEffect(() => {
    loadingTotalBeam();
    loadingInputBeam();
    loadingFinishBeam();
  }, []);
  const loadingTotalBeam = () => {
    const dataBodyCustomer = {
      finish: "0",
    };
    fetch("https://hoangthongtelecom.com/QLySoi/getTotalBeam.php", {
      method: "POST",
      body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueTotalBeam(result);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  const loadingInputBeam = () => {
    // const dataBodyCustomer = {
    //   OutBeam: "1",
    // };
    fetch("https://hoangthongtelecom.com/QLySoi/getInOutBeamWeb.php", {
      method: "POST",
     // body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        const qrIn = result.filter((item) => item.OutBeam === '0');
        setValueInputBeam(qrIn);
        const qrOut = result.filter((item) => item.OutBeam === '1');
        setValueOutBeam(qrOut);
       
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  const loadingFinishBeam = () => {
    const dataBodyCustomer = {
      finish: "1",
    };
    fetch("https://hoangthongtelecom.com/QLySoi/getTotalBeam.php", {
      method: "POST",
      body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueFinishBeam(result);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  return (
    <div>
      {isMobile && <></>}
      {isDesktop && (
        <>
          {valueTotalBeam === "" ? (
            <div
              style={{
                textAlign: "center",
                width: "100%",
                alignItems: "center",
                marginTop: "20%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="admin-page">
              <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="SUMMARY" value="1" style={{fontWeight:900}}/>
                      <Tab label="INPUT" value="2" style={{fontWeight:900}}/>
                      <Tab label="OUTPUT" value="3" style={{fontWeight:900}}/>
                      <Tab label="FINISH" value="4" style={{fontWeight:900}} />
                    </TabList>
                  </Box>
                  <TabPanel value="1" style={{ padding: 5 }}>
                    <TableContainer sx={{ maxHeight: "90vh" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead style={{ height: "30px" }}>
                          <TableRow>
                            <TableCell
                              align="center"
                              rowSpan={2}
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 5,
                              }}
                            >
                              STYLE
                            </TableCell>
                            <TableCell
                              align="center"
                              rowSpan={2}
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 5,
                              }}
                            >
                              ORDER NUMBER
                            </TableCell>
                            <TableCell
                              align="center"
                              rowSpan={2}
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 220,
                              }}
                            >
                              SUPPLIER
                            </TableCell>
                            <TableCell
                              align="center"
                              rowSpan={2}
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 5,
                              }}
                            >
                              UNIT
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={4}
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 5,
                                height: 20,
                              }}
                            >
                              INPUT
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={4}
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 5,
                                height: 20,
                              }}
                            >
                              OUTPUT
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={4}
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                               padding: 5,
                                height: 20,
                              }}
                            >
                              INVENTORY
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              key={"In200"}
                              align={"center"}
                              style={{
                                top: 36,
                                maxWidth: 60,
                                backgroundColor: "#31869B",
                                padding: 5,
                                color: "white",
                              }}
                            >
                              200
                            </TableCell>
                            <TableCell
                              key={"In300"}
                              align={"center"}
                              style={{
                                top: 36,
                                maxWidth: 60,
                                backgroundColor: "#31869B",
                                padding: 5,
                                color: "white",
                              }}
                            >
                              300
                            </TableCell>
                            <TableCell
                              key={"In400"}
                              align={"center"}
                              style={{
                                top: 36,
                                maxWidth: 60,
                                backgroundColor: "#31869B",
                                padding: 5,
                                color: "white",
                              }}
                            >
                              400
                            </TableCell>
                            <TableCell
                              key={"InTotal"}
                              align={"center"}
                              style={{
                                top: 36,
                                maxWidth: 60,
                                backgroundColor: "#31869B",
                                padding: 5,
                                color: "white",
                              }}
                            >
                              Total M
                            </TableCell>
                            <TableCell
                              key={"Out200"}
                              align={"center"}
                              style={{
                                top: 36,
                                maxWidth: 60,
                                backgroundColor: "#31869B",
                                padding: 5,
                                color: "white",
                              }}
                            >
                              200
                            </TableCell>
                            <TableCell
                              key={"Out300"}
                              align={"center"}
                              style={{
                                top: 36,
                                maxWidth: 60,
                                backgroundColor: "#31869B",
                                padding: 5,
                                color: "white",
                              }}
                            >
                              300
                            </TableCell>
                            <TableCell
                              key={"Out400"}
                              align={"center"}
                              style={{
                                top: 36,
                                maxWidth: 60,
                                backgroundColor: "#31869B",
                                padding: 5,
                                color: "white",
                              }}
                            >
                              400
                            </TableCell>
                            <TableCell
                              key={"OutTotal"}
                              align={"center"}
                              style={{
                                top: 36,
                                maxWidth: 60,
                                backgroundColor: "#31869B",
                                padding: 5,
                                color: "white",
                              }}
                            >
                              Total M
                            </TableCell>
                            <TableCell
                              key={"Inventor200"}
                              align={"center"}
                              style={{
                                top: 36,
                                maxWidth: 60,
                                backgroundColor: "#31869B",
                                padding: 5,
                                color: "white",
                              }}
                            >
                              200
                            </TableCell>
                            <TableCell
                              key={"Inventor300"}
                              align={"center"}
                              style={{
                                top: 36,
                                maxWidth: 60,
                                backgroundColor: "#31869B",
                                padding: 5,
                                color: "white",
                              }}
                            >
                              300
                            </TableCell>
                            <TableCell
                              key={"Inventor400"}
                              align={"center"}
                              style={{
                                top: 36,
                                maxWidth: 60,
                                backgroundColor: "#31869B",
                                padding: 5,
                                color: "white",
                              }}
                            >
                              400
                            </TableCell>
                            <TableCell
                              key={"InventorTotal"}
                              align={"center"}
                              style={{
                                top: 36,
                                maxWidth: 60,
                                backgroundColor: "#31869B",
                                padding: 5,
                                color: "white",
                              }}
                            >
                              Total M
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {valueTotalBeam === "" ? (
                            <></>
                          ) : (
                            valueTotalBeam.map((row) => (
                              <StyledTableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 1,
                                  },
                                }}
                              >
                                <TableCell
                                  align="left"
                                  // component="th"
                                  scope="row"
                                  style={{
                                    padding: 3,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.style}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: 5,
                                  }}
                                >
                                  {row.orderNumber}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.Supplier}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  TRỤC
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.input200}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.input300}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.input400}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.totalInput}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.output200}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.output300}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.output400}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.totaloutput}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                  }}
                                >
                                  {row.Ton200}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                  }}
                                >
                                  {row.Ton300}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                  }}
                                >
                                  {row.Ton400}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                  }}
                                >
                                  {row.TonTotal}
                                </TableCell>
                              </StyledTableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                  <TabPanel value="2" style={{ padding: 5 }}>
                  <TableContainer sx={{ maxHeight: "90vh" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead style={{ height: "50px" }}>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                              }}
                            >
                              INPUT DATE
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 5,
                              }}
                            >
                              STYLE
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 220,
                              }}
                            >
                              ORDER NUMBER
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 220,
                              }}
                            >
                              SUPPLIER
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 60,
                              }}
                            >
                              BEAM
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 60,
                              }}
                            >
                              M/BEAM
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 60,
                              }}
                            >
                              LOOM
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 150,
                              }}
                            >
                              NOTE
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {valueInputBeam === "" ? (
                            <></>
                          ) : (
                            valueInputBeam.map((row) => (
                              <StyledTableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 1,
                                  },
                                }}
                              >
                                <TableCell
                                  align="center"
                                  // component="th"
                                  scope="row"
                                  style={{
                                    padding: 3,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.ngay}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: 5,
                                  }}
                                >
                                  {row.style}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.orderNumber}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.Supplier}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.beam}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.metbeam}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.loom}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.note}
                                </TableCell>
                              </StyledTableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                  <TabPanel value="3" style={{ padding: 5 }}>
                  <TableContainer sx={{ maxHeight: "90vh" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead style={{ height: "50px" }}>
                          <TableRow>
                          <TableCell
                              align="center"
                              colSpan={9}
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                              }}
                            >
                             FACRORY 1
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={3}
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                              }}
                            >
                            MÁY DỆT
                            </TableCell>
                          </TableRow>
                          <TableRow>
                          <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth:90
                              }}
                            >
                              IN DATE
                            </TableCell>  
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                minWidth:90,
                                padding: 0,
                              }}
                            >
                              OUT DATE
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 5,
                              }}
                            >
                              STYLE
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 150,
                              }}
                            >
                              ORDER NUMBER
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth:290 ,
                              }}
                            >
                              SUPPLIER
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 60,
                              }}
                            >
                              BEAM
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 60,
                              }}
                            >
                              M/BEAM
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 60,
                              }}
                            >
                              LOOM
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 150,
                              }}
                            >
                              NOTE
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 90,
                              }}
                            >
                              NGÀY LÊN
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 70,
                              }}
                            >
                              SỐ MÁY
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 90,
                              }}
                            >
                              NGÀY XUỐNG
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {valueOutBeam === "" ? (
                            <></>
                          ) : (
                            valueOutBeam.map((row) => (
                              <StyledTableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 1,
                                  },
                                }}
                              >
                                <TableCell
                                  align="center"
                                  // component="th"
                                  scope="row"
                                  style={{
                                    padding: 3,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.ngay}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  // component="th"
                                  scope="row"
                                  style={{
                                    padding: 3,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.ngayOut}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: 5,
                                    minWidth:120
                                  }}
                                >
                                  {row.style}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.orderNumber}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.Supplier}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.beam}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.metbeam}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.loom}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.note}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.ngayOut}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.Machines_Number}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  
                                </TableCell>
                              </StyledTableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                  <TabPanel value="4" style={{ padding: 5 }}>
                  <TableContainer sx={{ maxHeight: "90vh" }}>
                      <Table stickyHeader aria-label="sticky table" size='small'>
                        <TableHead style={{ height: "30px" }}>
                          <TableRow>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 5,
                                maxWidth: 150,
                              }}
                            >
                              STYLE
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                maxWidth: 170,
                              }}
                            >
                              ORDER NUMBER
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                maxWidth: 180,
                              }}
                            >
                              SUPPLIER
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 60,
                              }}
                            >
                              200
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 60,
                              }}
                            >
                              300
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 60,
                              }}
                            >
                              400
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 60,
                              }}
                            >
                              UNIT
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: "#31869B",
                                color: "white",
                                fontWeight: "bold",
                                padding: 0,
                                minWidth: 60,
                              }}
                            >
                              TOTAL
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {valueFinishBeam === "" ? (
                            <></>
                          ) : (
                            valueFinishBeam.map((row) => (
                              <StyledTableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 1,
                                  },
                                }}
                              >
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: 5,
                                  }}
                                >
                                  {row.style}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.orderNumber}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.Supplier}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.input200}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.input300}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.input400}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  TRỤC
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    padding: 5,
                                    fontSize: 15,
                                  }}
                                >
                                  {row.totalInput}
                                </TableCell>
                              </StyledTableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default BeamPage;
