/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Button from "@mui/material/Button";
import dayjs from "dayjs";

import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
  [`&:first-child td, &:first-child th`]: {
    position: "sticky",
    left: 0,
  },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor:"#DDD9C4",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function CheckRollPage() {
  const [valueTotalBeam, setValueTotalBeam] = React.useState("");
  const [value, setValue] = React.useState("1");
  const [valueDetail, setValueDetail] = React.useState("");
  const [valueDetailShow, setValueDetailShow] = React.useState("");
  const [valueFillterDate, setValueFillterDate] = React.useState("");
  const [valueStyle, setValueStyle] = React.useState("1");
  const [selectedDateTimeFrom, setSelectedDateTimeFrom] = useState(
    dayjs(new Date())
  );
  const [selectedDateTimeTo, setSelectedDateTimeTo] = useState(
    dayjs(new Date())
  );
  const [valueOutput, setValueOutput] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  React.useEffect(() => {
    // loadingDetail();
    loadingTotalBeam();
  }, [valueOutput]);
  const loadingTotalBeam = () => {
    const dataBodyCustomer = {
      finish: "0",
    };
    fetch("https://hoangthongtelecom.com/KiemVaiLienA/getTotal.php", {
      method: "POST",
      body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueTotalBeam(result);
       // console.log(result);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };

  const loadingDetail = async (value) => {
    const dataBodyCustomer1 = {
      mahang: value,
    };
    const findStyle = valueTotalBeam.filter((item) => item.mahang === value);
    setValueStyle(findStyle[0].style);
    console.log(findStyle[0].style);
    await fetch(
      "https://hoangthongtelecom.com/KiemVaiLienA/getDetailCheckWeb.php?",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataBodyCustomer1),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setValueDetail(result);
        console.log(result);
        setValueDetailShow(result);
        const uniqueDates = [...new Set(result.map((item) => item.ngaykiem))];
        const uniqueData = uniqueDates.map((ngaykiem) => ({ ngaykiem }));
        setValueFillterDate(uniqueData);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };

  
  const loadSearchData = () => {

    const valueThoigianFrom = new Intl.DateTimeFormat("en-gb", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(selectedDateTimeFrom);
    const datePartFrom = valueThoigianFrom.split(",")[0].trim(); // Tách phần ngày
    const datePartsFrom = datePartFrom.split("/"); // Tách ngày, tháng, năm
    const convertDateTimeFrom = `${datePartsFrom[2]}-${datePartsFrom[1]}-${datePartsFrom[0]}`;
    console.log(convertDateTimeFrom);


    const valueThoigianTo = new Intl.DateTimeFormat("en-gb", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(selectedDateTimeTo);
    const datePartTo = valueThoigianTo.split(",")[0].trim(); // Tách phần ngày
    const datePartsTo= datePartTo.split("/"); // Tách ngày, tháng, năm
    const convertDateTimeTo = `${datePartsTo[2]}-${datePartsTo[1]}-${datePartsTo[0]}`;

    const dataBodyCustomer = {
      fromngay: convertDateTimeFrom,
      toNgay: convertDateTimeTo,
    };
    //console.log(value);
   fetch(
      "https://hoangthongtelecom.com/KiemVaiLienA/getHistoryXuatVai.php",
      {
        method: "POST",
        body: JSON.stringify(dataBodyCustomer),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setValueOutput(result);
        console.log(result);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  return (
    <div style={{ marginLeft: "180px" }}>
      {valueTotalBeam === "" ? (
        <>
          <div
            style={{
              textAlign: "center",
              width: "100%",
              alignItems: "center",
              marginTop: "20%",
            }}
          >
            <CircularProgress />
          </div>
        </>
      ) : (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="TOTAL" value="1" style={{ fontWeight: 900 }} />
              <Tab label="DETAIL" value="2" style={{ fontWeight: 900 }} />
              <Tab label="OUTPUT" value="3" style={{ fontWeight: 900 }} />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: 5 }}>
            <TableContainer
              sx={{
                maxHeight: "90vh",
                fontFamily: "Times New Roman",
                overflowX: "auto",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead style={{ height: "50px" }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      rowSpan={2}
                      style={{
                        backgroundColor: "#31869B",
                        color: "white",
                        fontWeight: "bold",
                        padding: 5,
                        minWidth: 100,
                        fontFamily: "Times New Roman",
                      }}
                    >
                      STYLE
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={12}
                      style={{
                        backgroundColor: "#31869B",
                        color: "white",
                        fontWeight: "bold",
                        padding: 5,
                        fontFamily: "Times New Roman",
                        //height: 20,
                      }}
                    >
                      GRADE
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      style={{
                        backgroundColor: "#31869B",
                        color: "white",
                        fontWeight: "bold",
                        padding: 5,
                        fontFamily: "Times New Roman",
                        // height: 20,
                        minWidth: 150,
                      }}
                    >
                      TOTAL INVENTORY
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      style={{
                        backgroundColor: "#31869B",
                        color: "white",
                        fontWeight: "bold",
                        padding: 5,
                        fontFamily: "Times New Roman",
                        // height: 20,
                        minWidth: 130,
                      }}
                    >
                      TOTAL OUTPUT
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      style={{
                        backgroundColor: "#31869B",
                        color: "white",
                        fontWeight: "bold",
                        padding: 5,
                        fontFamily: "Times New Roman",
                        // height: 20,
                        maxWidth: 60,
                      }}
                    ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      key={"AMetter"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      A
                    </TableCell>
                    <TableCell
                      key={"ARoll"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      ROLL
                    </TableCell>
                    <TableCell
                      key={"BMetter"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      B
                    </TableCell>
                    <TableCell
                      key={"BRoll"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      ROLL
                    </TableCell>
                    <TableCell
                      key={"CMetter"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      C
                    </TableCell>
                    <TableCell
                      key={"CRoll"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      ROLL
                    </TableCell>
                    <TableCell
                      key={"XMetter"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      X
                    </TableCell>
                    <TableCell
                      key={"XRoll"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      ROLL
                    </TableCell>
                    <TableCell
                      key={"Ymetter"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      Y
                    </TableCell>
                    <TableCell
                      key={"YRoll"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      ROLL
                    </TableCell>
                    <TableCell
                      key={"Zmeter"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      Z
                    </TableCell>
                    <TableCell
                      key={"ZRollTotal"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      ROLL
                    </TableCell>
                    <TableCell
                      key={"InventoryMetterTotal"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      METTERS
                    </TableCell>
                    <TableCell
                      key={"InventoryRollTotal"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      ROLL
                    </TableCell>
                    <TableCell
                      key={"OutMetterTotal"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      METTERS
                    </TableCell>
                    <TableCell
                      key={"OutRollTotal"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      ROLL
                    </TableCell>
                    <TableCell
                      key={"PercentTotal"}
                      align={"center"}
                      style={{
                        top: 36,
                        maxWidth: 60,
                        backgroundColor: "#31869B",
                        padding: 5,
                        color: "white",
                        fontFamily: "Times New Roman",
                      }}
                    >
                      %
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {valueTotalBeam === "" ? (
                    <></>
                  ) : (
                    valueTotalBeam.map((row) => (
                      <StyledTableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 1,
                          },
                        }}
                      >
                        <TableCell
                          align="left"
                          // component="th"
                          scope="row"
                          style={{
                            padding: 3,
                            fontSize: 15,
                          }}
                        >
                          {row.mahang}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                          }}
                        >
                          {row.A ==='0'?'-':row.A}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                            fontSize: 15,
                          }}
                        >
                          {row.CountA ==='0'?'-':row.CountA}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                            fontSize: 15,
                          }}
                        >
                          {row.B ==='0'?'-':row.B}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                            fontSize: 15,
                          }}
                        >
                          {row.CountB ==='0'?'-':row.CountB}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                            fontSize: 15,
                          }}
                        >
                          {row.C ==='0'?'-':row.C}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                            fontSize: 15,
                          }}
                        >
                          {row.CountC ==='0'?'-':row.CountC}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                            fontSize: 15,
                          }}
                        >
                          {row.X ==='0'?'-':row.X}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                            fontSize: 15,
                          }}
                        >
                          {row.CountX ==='0'?'-':row.CountX}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                            fontSize: 15,
                          }}
                        >
                          {row.Y ==='0'?'-':row.Y}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                            fontSize: 15,
                          }}
                        >
                          {row.CountY ==='0'?'-':row.CountY}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                            fontSize: 15,
                          }}
                        >
                          {row.Z ==='0'?'-':row.Z}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                          }}
                        >
                          {row.CountZ ==='0'?'-':row.CountZ}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                          }}
                        >
                          {row.total}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                          }}
                        >
                          {row.totalRoll}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                          }}
                        >
                          {row.sumOut}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                          }}
                        >
                          {row.totalRollOut}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            padding: 5,
                            color: "red",
                            fontWeight: 800,
                          }}
                        >
                          {(
                            ((Number(row.sumOut) +
                              Number(row.A) +
                              Number(row.X)) /
                              (Number(row.sumOut) + Number(row.total))) *
                            100
                          ).toFixed(2)}
                        </TableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value="2" style={{ padding: 5 }}>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={valueTotalBeam.map((option) => option.mahang)}
                  sx={{ width: 200 }}
                  size="small"
                  //   onChange={ loadingDetail()}
                  onInputChange={(event, newInputValue) => {
                    loadingDetail(newInputValue);
                    // setValueMahang(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Mã hàng" />
                  )}
                />
              </div>
              <div style={{ width: 10 }}></div>
              <div>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={valueFillterDate}
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.ngaykiem}
                  onChange={(event, newValue) => {
                    if(newValue.length ===0){
                      setValueDetailShow(valueDetail);
                    }else{
                      const foundObjects = valueDetail.filter(arrayOfObject =>
                        newValue.some(objectToFind =>
                          objectToFind.ngaykiem === arrayOfObject.ngaykiem
                        )
                      );
                      setValueDetailShow(foundObjects);
                    }
                    
                     
                      
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.ngaykiem}
                    </li>
                  )}
                  style={{ width: 330 }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Lọc ngày" />
                  )}
                />
              </div>
              <div style={{ width: 10 }}></div>
              <div>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={valueTotalBeam}
                  disableCloseOnSelect
                  size="small"
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </li>
                  )}
                  style={{ width: 150 }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Phân loại" />
                  )}
                />
              </div>
            </div>
            {valueStyle === "1" ? (
              <TableContainer
                sx={{
                  height: "82vh",
                  fontFamily: "Times New Roman",
                  overflowX: "auto",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead style={{ height: "50px" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          minWidth: 80,
                          fontFamily: "Times New Roman",
                        }}
                      >
                        NGÀY KIỂM
                      </TableCell>

                      <TableCell
                        align="center"
                        // colSpan={12}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          //height: 20,
                        }}
                      >
                        SỐ MÁY
                      </TableCell>
                      <TableCell
                        align="center"
                       // colSpan={2}
                       rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 45,
                        }}
                      >
                        CA
                      </TableCell>
                      <TableCell
                        align="center"
                        //colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 80,
                        }}
                      >
                        NGÀY CẮT VẢI
                      </TableCell>
                      <TableCell
                        align="center"
                        //colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >STT</TableCell>
                      <TableCell
                        align="center"
                        //colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >PHÂN LOẠI</TableCell>
                      <TableCell
                        align="center"
                        //colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >SỐ MÉT</TableCell>
                      <TableCell
                        align="center"
                        //colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >NGƯỜI KIỂM</TableCell>
                      <TableCell
                        align="center"
                        colSpan={4}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >DẦY CHỚN NGANG</TableCell>
                      <TableCell
                        align="center"
                        colSpan={4}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 400,
                        }}
                      >THƯA</TableCell>
                      <TableCell
                        align="center"
                        colSpan={4}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >LÁT NGANG KHÚC</TableCell>
                      <TableCell
                        align="center"
                        colSpan={4}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >RÚT SỢI</TableCell>
                      <TableCell
                        align="center"
                        colSpan={4}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >DỌC</TableCell>
                      <TableCell
                        align="center"
                        colSpan={4}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >DẦU</TableCell>
                      <TableCell
                        align="center"
                        colSpan={4}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >DƠ</TableCell>
                      <TableCell
                        align="center"
                        //colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >Note</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >0 - 100</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >100 - 200</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >200 - 300</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >300 - 400</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          width: 100,
                        }}
                      >0 - 100</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >100 - 200</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >200 - 300</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >300 - 400</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >0 - 100</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >100 - 200</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >200 - 300</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >300 - 400</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >0 - 100</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >100 - 200</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >200 - 300</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >300 - 400</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >0 - 100</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >100 - 200</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >200 - 300</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >300 - 400</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >0 - 100</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >100 - 200</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >200 - 300</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >300 - 400</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >0 - 100</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >100 - 200</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >200 - 300</TableCell>
                       <TableCell
                        align="center"
                        style={{
                          top:36,
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >300 - 400</TableCell>
                    </TableRow>
                    
                  </TableHead>
                  <TableBody>
                    {valueDetailShow === "" ? (
                      <></>
                    ) : (
                      valueDetailShow.map((row) => (
                        <StyledTableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 1,
                            },
                          }}
                        >
                          <TableCell
                            align="left"
                            // component="th"
                            scope="row"
                            style={{
                              padding: 3,
                              fontSize: 15,
                            }}
                          >
                            {row.ngaykiem}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                            }}
                          >
                            {row.somay}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.ca}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.ngaycatvai}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.stt}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.phanloai}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.somet}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.nguoikiem}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.DCN100}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.DCN200}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.DCN300}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.DCN400}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                            }}
                          >
                            {row.T100}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                            }}
                          >
                            {row.T200}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                            }}
                          >
                            {row.T300}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                            }}
                          >
                            {row.T400}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                            }}
                          >
                            {row.LNK100}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                             // fontWeight: 800,
                            }}
                          >
                            {row.LNK200}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                             // fontWeight: 800,
                            }}
                          >
                            {row.LNK300}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                             // fontWeight: 800,
                            }}
                          >
                            {row.LNK400}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.R100}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.R200}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.R300}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.R400}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.Doc100}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.Doc200}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.Doc300}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.Doc400}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.Do100}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.Do200}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.Do300}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.Do400}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.Dau100}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.Dau200}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.Dau300}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.Dau400}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "black",
                              //fontWeight: 800,
                            }}
                          >
                            {row.note}
                          </TableCell>
                        </StyledTableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer
                sx={{
                  maxHeight: "82vh",
                  fontFamily: "Times New Roman",
                  overflowX: "auto",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                <TableHead style={{ height: "50px" }}>
                    <TableRow>
                      <StyledTableCell
                        align="center"
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          minWidth: 80,
                          fontFamily: "Times New Roman",
                        }}
                      >
                        NGÀY KIỂM
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        // colSpan={12}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          //height: 20,
                        }}
                      >
                        SỐ MÁY
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                       // colSpan={2}
                       rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 45,
                        }}
                      >
                        CA
                      </StyledTableCell>
                      <TableCell
                        align="center"
                        //colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 80,
                        }}
                      >
                        NGÀY CẮT VẢI
                      </TableCell>
                      <TableCell
                        align="center"
                        //colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >STT</TableCell>
                      <TableCell
                        align="center"
                        //colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >PHÂN LOẠI</TableCell>
                      <TableCell
                        align="center"
                        //colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >SỐ MÉT</TableCell>
                      <TableCell
                        align="center"
                        //colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          maxWidth: 60,
                        }}
                      >NGƯỜI KIỂM</TableCell>
                      <TableCell
                        align="center"
                        colSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >DẦY CHỚN NGANG</TableCell>
                      <TableCell
                        align="center"
                        colSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >THƯA</TableCell>
                      <TableCell
                        align="center"
                        colSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >LÁT NGANG KHÚC</TableCell>
                      <TableCell
                        align="center"
                        colSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >RÚT SỢI</TableCell>
                      <TableCell
                        align="center"
                        colSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >DỌC</TableCell>
                      <TableCell
                        align="center"
                        colSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >DẦU</TableCell>
                      <TableCell
                        align="center"
                        colSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >DƠ</TableCell>
                      <TableCell
                        align="center"
                        //colSpan={2}
                        rowSpan={2}
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          fontFamily: "Times New Roman",
                          // height: 20,
                          minWidth: 100,
                        }}
                      >Note</TableCell>
                    </TableRow>
                                        
                  </TableHead>
                  <TableBody>
                    {valueDetail === "" ? (
                      <></>
                    ) : (
                      valueDetail.map((row) => (
                        <StyledTableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 1,
                            },
                          }}
                        >
                          <StyledTableCell
                            align="left"
                            // component="th"
                            scope="row"
                            style={{
                              padding: 3,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                            }}
                          >
                            {row.ngaykiem}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                            }}
                          >
                            {row.somay}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              left:200,
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                            }}
                          >
                            {row.ca}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                            }}
                          >
                            {row.ngaycatvai}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                            }}
                          >
                            {row.stt}
                          </StyledTableCell>
                          <TableCell
                            align="center"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                            }}
                          >
                            {row.phanloai}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                            }}
                          >
                            {row.somet}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                            }}
                          >
                            {row.nguoikiem}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                              color: "blue",
                            }}
                          >
                            {row.daychonngan}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                              color:'red'
                            }}
                          >
                            {row.daychonngan1}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                              color: "blue",
                            }}
                          >
                            {row.thua }
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                              color:'red'
                            }}
                          >
                            {row.thua1}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                              color: "blue",
                            }}
                          >
                            {row.latngangkhuc}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                              color:'red'
                            }}
                          >
                            {row.latngangkhuc1}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                              color: "blue",
                            }}
                          >
                            {row.rutsoi}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                              color:'red'
                            }}
                          >
                            {row.rutsoi1}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                              color: "blue",
                            }}
                          >
                            {row.doc}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                              color: "red",
                             // fontWeight: 800,
                            }}
                          >
                            {row.doc1}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "blue",
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                              //color: "red",
                             // fontWeight: 800,
                            }}
                          >
                            {row.errordo}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "red",
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                             // fontWeight: 800,
                            }}
                          >
                            {row.do1}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "blue",
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                             // fontWeight: 800,
                            }}
                          >
                            {row.dau}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 5,
                              color: "red",
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                             // fontWeight: 800,
                            }}
                          >
                            {row.dau1}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: 5,
                              fontSize: 16,
                              fontFamily: "Times New Roman",
                              //color: "red",
                             // fontWeight: 800,
                            }}
                          >
                            {row.note}
                          </TableCell>
                        </StyledTableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TabPanel>
          <TabPanel value="3" style={{ padding: 5 }}>
            <div style={{ display: "flex", marginBottom: 10 }}>
              <div>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"en-gb"}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={selectedDateTimeFrom}
                    onChange={(newValue) => {

                      setSelectedDateTimeFrom(newValue);
                    }}
                    slotProps={{ textField: { size: "small" } }}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ width: 10 }}></div>
              <div>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"en-gb"}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={selectedDateTimeTo}
                    onChange={(newValue) => {
                      setSelectedDateTimeTo(newValue);
                    }}
                    slotProps={{ textField: { size: "small" } }}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ width: 10 }}></div>
              <div>
                <Button onClick={loadSearchData} variant="contained">
                  Search
                </Button>
              </div>
            </div>
            {valueOutput.length === 0 ? (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "95vh",
                    textAlign: "center",
                    alignItems: "center",
                    marginTop: 150,
                  }}
                >
                  Không có dữ liệu
                </div>
              </>
            ) : (
              <TableContainer sx={{ maxHeight: "97vh" }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead style={{ height: "30px" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 0,
                          width: 150,
                        }}
                      >
                        Ngày xuất
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                          width: 150,
                        }}
                      >
                        Mã hàng
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 0,
                          width: 100,
                        }}
                      >
                        Metters
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          padding: 5,
                        }}
                      >
                        Roll
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#31869B",
                          color: "white",
                          fontWeight: "bold",
                          // padding: 5,
                          height: 20,
                        }}
                      >
                        Note
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {valueOutput === "" ? (
                      <></>
                    ) : (
                      valueOutput.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 1,
                            },
                          }}
                        >
                          <TableCell
                            align="center"
                            // component="th"
                            scope="row"
                            style={{
                              padding: 3,
                              fontSize: 15,
                            }}
                          >
                            {row.ngayxuatvai}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: 5,
                            }}
                          >
                            {row.mahang}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.totalSomet}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.totalSocay}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              padding: 5,
                              fontSize: 15,
                            }}
                          >
                            {row.note}
                          </TableCell>
                          
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TabPanel>
        </TabContext>
      )}
    </div>
  );
}

export default CheckRollPage;
