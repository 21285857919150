/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import Box from "@mui/material/Box";
import logoBig from "./Image/desktop-logo.png";
import lagEng from "./Image/flag-eng.png";
import lagVn from "./Image/flag-vietnam.png";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import imgMision from "./Image/mision_vision.jpg";
import { useMediaQuery } from "react-responsive";
import imgMap from "./Image/ImageMap.png";

function AboutVNPage() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  let navigate = useNavigate();
  const changLangueVietNam = () => {
    let path = "/contact-vn";
    navigate(path);
  };
  const changLangueEnglish = () => {
    let path = "/contact-eng";
    navigate(path);
  };

  const linkToMision = () => {
    let path = "/misionVN";
    navigate(path);
  };
  const linkToMisionEng = () => {
    let path = "/misionENG";
    navigate(path);
  };

  const linkProductVN = () => {
    let path = "/productVN";
    navigate(path);
  };

  const linkToHome=()=>{
    let path = "/";
    navigate(path);
  }

  return (
    <div>
      {isMobile && (
        <>
          <div style={{ display: "flex", padding: "8px" }}>
            <div style={{ flexGrow: 1 }}>
              <a href="/" className="linkLogo-mobile">
                <img src={logoBig} style={{ width: "60px" }} />
                <div style={{ marginTop: "15px" }}>UNITEDASIA.CO</div>
              </a>
            </div>
            <div className="headerLangue">
              <Stack direction="row" spacing={2}>
                <Avatar
                  alt="Remy Sharp"
                  src={lagVn}
                  onClick={changLangueVietNam}
                  style={{ cursor: "pointer", width: 30, height: 30 }}
                />
                <Avatar
                  alt="Remy Sharp"
                  src={lagEng}
                  onClick={changLangueEnglish}
                  style={{ cursor: "pointer", width: 30, height: 30 }}
                />
              </Stack>
            </div>
          </div>
          <div class="headerLangue-mobile">
            <ul>
              <li onClick={linkToHome}>TRANG CHỦ</li>
              <li onClick={linkProductVN}>SẢN PHẨM</li>
              <li onClick={linkToMision}>SỨ MỆNH VÀ TẦM NHÌN</li>
              <li>LIÊN HỆ</li>
            </ul>
          </div>
          <hr />
          <div style={{ padding: "8px" }}>
          <div
              style={{
                fontSize: "18px",
                fontFamily: "Times New Roman",
                fontWeight: "bold",
                marginBottom: "10px",
                marginTop: "20px",
              }}
            >
              CÔNG TY TNHH LIÊN Á - UNITED ASIA
            </div>
            <div  style={{
                fontSize: "18px",
                fontFamily: "Times New Roman",
                marginBottom: "5px",
                marginTop: "2px",
              }}>
              Địa chỉ : Lô CN18, Đường số 4, KCN Sóng Thần 3, Phường Phú Tân,
              Thủ Dầu Một, Bình Dương
            </div>
            <div style={{
                fontSize: "18px",
                fontFamily: "Times New Roman",
                marginBottom: "5px",
                marginTop: "2px",
              }}>Mã số thuế : 3701926362</div>
            <div style={{
                fontSize: "18px",
                fontFamily: "Times New Roman",
                marginBottom: "5px",
                marginTop: "2px",
              }}>Email : <a href="mailto: info@unitedasia.co">info@unitedasia.co</a></div>
            <div style={{
                fontSize: "18px",
                fontFamily: "Times New Roman",
                marginBottom: "5px",
                marginTop: "2px",
              }}>Website : <a href="http://www.unitedasia.co">http://unitedasia.co</a></div>
            <div style={{marginTop:'30px'}}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7832.57232134478!2d106.7008378!3d11.0171433!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d067ec2817b3%3A0x6d0c92e985f83cf6!2sC%C3%94NG%20TY%20TNHH%20LI%C3%8AN%20%C3%81%20-%20UNITED%20ASIA%20CO.%2C%20LTD!5e0!3m2!1sen!2s!4v1694505755432!5m2!1sen!2s" // URL của trang bạn muốn nhúng
                title="Embedded Page"
                width="100%"
                height="600"
                frameBorder="0"
              ></iframe>
            </div>
            <div className="imagemap-mobile">
            <a href="https://www.google.com/maps/place/C%C3%94NG+TY+TNHH+LI%C3%8AN+%C3%81+-+UNITED+ASIA+CO.,+LTD/@17.860977,98.3730544,4.84z/data=!4m6!3m5!1s0x3174d067ec2817b3:0x6d0c92e985f83cf6!4b1!8m2!3d11.0171433!4d106.7008378">
                <img src={imgMap} width={300}/>
            </a>
            </div>
          </div>
        </>
      )}
      {isDesktop && (
        <>
          <Box
            sx={{
              maxWidth: "100%",
              flexGrow: 1,
              marginLeft: "8%",
              marginRight: "8%",
              marginTop: "10px",
            }}
          >
            <div style={{ height: "80px", display: "flex" }}>
              <div>
                <a href="/" className="linkLogo">
                  <img src={logoBig} style={{ width: "80px" }} />
                  <div style={{ marginTop: "15px" }}>UNITEDASIA.CO</div>
                </a>
              </div>
              <div className="headerRight">
                <div className="headerLangue">
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Remy Sharp"
                      src={lagVn}
                      onClick={changLangueVietNam}
                      style={{ cursor: "pointer", width: 30, height: 30 }}
                    />
                    <Avatar
                      alt="Remy Sharp"
                      src={lagEng}
                      onClick={changLangueEnglish}
                      style={{ cursor: "pointer", width: 30, height: 30 }}
                    />
                  </Stack>
                </div>
                <div class="clearfix"></div>
                <div class="headerLangue">
                  <ul>
                    <li onClick={linkToHome}>TRANG CHỦ</li>
                    <li onClick={linkProductVN}>SẢN PHẨM</li>
                    <li onClick={linkToMision}>SỨ MỆNH VÀ TẦM NHÌN</li>
                    <li>LIÊN HỆ</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{
                fontSize: "20px",
                fontFamily: "Times New Roman",
                fontWeight: "bold",
                marginBottom: "10px",
                marginTop: "20px",
              }}
            >
              CÔNG TY TNHH LIÊN Á - UNITEDASIA
            </div>
            <div  style={{
                fontSize: "18px",
                fontFamily: "Times New Roman",
                marginBottom: "5px",
                marginTop: "2px",
              }}>
              Địa chỉ : Lô CN18, Đường số 4, KCN Sóng Thần 3, Phường Phú Tân,
              Thủ Dầu Một, Bình Dương
            </div>
            <div style={{
                fontSize: "18px",
                fontFamily: "Times New Roman",
                marginBottom: "5px",
                marginTop: "2px",
              }}>Mã số thuế : 3701926362</div>
            <div style={{
                fontSize: "18px",
                fontFamily: "Times New Roman",
                marginBottom: "5px",
                marginTop: "2px",
              }}>Email : <a href="mailto: info@unitedasia.co">info@unitedasia.co</a></div>
            <div style={{
                fontSize: "18px",
                fontFamily: "Times New Roman",
                marginBottom: "5px",
                marginTop: "2px",
              }}>Website : <a href="http://www.unitedasia.co">http://unitedasia.co</a></div>
            <div style={{marginTop:'30px'}}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7832.57232134478!2d106.7008378!3d11.0171433!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d067ec2817b3%3A0x6d0c92e985f83cf6!2sC%C3%94NG%20TY%20TNHH%20LI%C3%8AN%20%C3%81%20-%20UNITED%20ASIA%20CO.%2C%20LTD!5e0!3m2!1sen!2s!4v1694505755432!5m2!1sen!2s" // URL của trang bạn muốn nhúng
                title="Embedded Page"
                width="100%"
                height="600"
                frameBorder="0"
              ></iframe>
            </div>
            <div className="imagemap">
            <a href="https://www.google.com/maps/place/C%C3%94NG+TY+TNHH+LI%C3%8AN+%C3%81+-+UNITED+ASIA+CO.,+LTD/@17.860977,98.3730544,4.84z/data=!4m6!3m5!1s0x3174d067ec2817b3:0x6d0c92e985f83cf6!4b1!8m2!3d11.0171433!4d106.7008378">
                <img src={imgMap} width={300}/>
            </a>
            </div>
          </Box>
        </>
      )}
    </div>
  );
}
export default AboutVNPage;
