/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import logoBig from "./Image/desktop-logo.png";
import lagEng from "./Image/flag-eng.png";
import lagVn from "./Image/flag-vietnam.png";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import bando from "./Image/bando.png";
import logoGobal from "./Image/Global.jpg";
import { useMediaQuery } from "react-responsive";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    imgPath: "http://unitedasia.co/Image/maydet_n1.jpg",
  },
  {
    label: "Bird",
    imgPath:
      "http://unitedasia.co/Image/MayHoSoi_auto_x2_colored_toned_light_ai.jpg",
  },
  {
    label: "Bali, Indonesia",
    imgPath:
      "http://unitedasia.co/Image/slide2_auto_x2_colored_toned_light_ai1.jpg",
  },
  {
    label: "Goč, Serbia",
    imgPath: "http://unitedasia.co/Image/mg1.png",
  },
];
function MyHomeEngPage() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  let navigate = useNavigate();
  const changLangueVietNam = () => {
    let path = "/";
    navigate(path);
  };
  const changLangueEnglish = () => {
    let path = "/homeEng";
    navigate(path);
  };

  const linkProductEng = () => {
    let path = "/productENG";
    navigate(path);
  };
  const linkMissionEng = () => {
    let path = "/misionENG";
    navigate(path);
  };

  const linkToPageContactEng = () => {
    let path = "/contact-eng";
    navigate(path);
  };
  return (
    <div>
      {isMobile && (
        <>
          <div style={{ display: "flex", padding: "8px" }}>
            <div style={{ flexGrow: 1 }}>
              <a href="/" className="linkLogo-mobile">
                <img src={logoBig} style={{ width: "60px" }} />
                <div style={{ marginTop: "15px" }}>UNITEDASIA.CO</div>
              </a>
            </div>
            <div className="headerLangue">
              <Stack direction="row" spacing={2}>
                <Avatar
                  alt="Remy Sharp"
                  src={lagVn}
                  onClick={changLangueVietNam}
                  style={{ cursor: "pointer", width: 30, height: 30 }}
                />
                <Avatar
                  alt="Remy Sharp"
                  src={lagEng}
                  onClick={changLangueEnglish}
                  style={{ cursor: "pointer", width: 30, height: 30 }}
                />
              </Stack>
            </div>
          </div>
          <div class="headerLangue-mobile">
            <ul>
              <li style={{width:'18%',fontSize:'13px'}} onClick={changLangueEnglish}>HOME</li>
              <li style={{width:'18%',fontSize:'13px'}}onClick={linkProductEng}>PRODUCT</li>
              <li style={{width:'43%',fontSize:'13px'}}onClick={linkMissionEng}>MISSION AND VISION</li>
              <li style={{width:'20%',fontSize:'13px'}}onClick={linkToPageContactEng}>CONTACT</li>
            </ul>
          </div>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    sx={{
                      height: 255,
                      display: "block",
                      maxWidth: "100%",
                      overflow: "hidden",
                      width: "100%",
                    }}
                    src={step.imgPath}
                    alt={step.label}
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            style={{ marginTop: "-150px" }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
              
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
               
              </Button>
            }
          />
          <div className="textAbout-mobile">ABOUT UNITEDASIA</div>
          <div
            style={{ marginTop: "30px", marginLeft: "5px", marginRight: "5px" }}
          >
            <div className="txtAbout">
              Our Company United Asia Co., Ltd. was established in 2011. We are
              a weaving mill specialized in synthetic woven fabric production.
              We cooperate closely with our business partners and improve
              ourselves constantly. Our fabrics are used in a wide variety of
              products and sectors, such as; garments (mostly outerwear and
              sportswear) , shoes, home textiles, and the advertisement
              industry. Our main markets are Europe (mostly Türkiye), Japan and
              other countries.
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <img src={bando} style={{ width: "100%" }} />
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "20px",
              marginTop: "20px",
            }}
          >
            Global Recycled Standard
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            <img src={logoGobal} style={{ width: "227px" }} />
          </div>
        </>
      )}
      {isDesktop && (
        <>
          <Box
            sx={{
              maxWidth: "100%",
              flexGrow: 1,
              marginLeft: "8%",
              marginRight: "8%",
              marginTop: "10px",
            }}
          >
            <div style={{ height: "90px", display: "flex" }}>
              <div>
                <a href="/homeEng" className="linkLogo">
                  <img src={logoBig} style={{ width: "80px" }} />
                  <div style={{ marginTop: "15px" }}>UNITEDASIA.CO</div>
                </a>
              </div>
              <div className="headerRight">
                <div className="headerLangue">
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Remy Sharp"
                      src={lagVn}
                      onClick={changLangueVietNam}
                      style={{ cursor: "pointer", width: 30, height: 30 }}
                    />
                    <Avatar
                      alt="Remy Sharp"
                      src={lagEng}
                      onClick={changLangueEnglish}
                      style={{ cursor: "pointer", width: 30, height: 30 }}
                    />
                  </Stack>
                </div>
                <div class="clearfix"></div>
                <div class="headerLangue">
                  <ul>
                    <li onClick={changLangueEnglish}>HOME</li>
                    <li onClick={linkProductEng}>PRODUCT</li>
                    <li onClick={linkMissionEng}>MISSION AND VISION</li>
                    <li onClick={linkToPageContactEng}>CONTACT</li>
                  </ul>
                </div>
              </div>
            </div>
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {images.map((step, index) => (
                <div key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      component="img"
                      sx={{
                        height: 455,
                        display: "block",
                        maxWidth: "100%",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      src={step.imgPath}
                      alt={step.label}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              style={{ marginTop: "-250px" }}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
            <div className="textAbout">ABOUT UNITEDASIA</div>
            <div style={{ marginTop: "30px" }}>
              <div className="txtAbout">
                Our Company United Asia Co., Ltd. was established in 2011. We
                are a weaving mill specialized in synthetic woven fabric
                production. We cooperate closely with our business partners and
                improve ourselves constantly. Our fabrics are used in a wide
                variety of products and sectors, such as; garments (mostly
                outerwear and sportswear) , shoes, home textiles, and the
                advertisement industry. Our main markets are Europe (mostly
                Türkiye), Japan and other countries.
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <img src={bando} style={{ width: "700px" }} />
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                marginTop: "20px",
              }}
            >
              Global Recycled Standard
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img src={logoGobal} style={{ width: "227px" }} />
            </div>
          </Box>
        </>
      )}
    </div>
  );
}
export default MyHomeEngPage;
