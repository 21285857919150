import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

function OrderMobilePage() {
  const [valueTotalSoi, setValueSoiBeam] = React.useState("");
  React.useEffect(() => {
    loadingTotalSoi();
    // ref.current.ownerDocument.body.scrollTop = 0;
  }, []);
  const loadingTotalSoi = () => {
    fetch("https://hoangthongtelecom.com/QLySoi/selectDonHang.php", {
      method: "POST",
      //   body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueSoiBeam(result);
        console.log(result);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  return (
    <div
      style={{
        backgroundColor: "gray",
        padding: 5,
      }}
    >
      {valueTotalSoi === "" ? (
        <div></div>
      ) : (
        valueTotalSoi.map((item) => (
          <Card sx={{ minWidth: 275, marginBottom: "5px", padding: 0 }}>
            <CardContent style={{ margin: 0, padding: 10 }}>
              <div style={{ display: "flex" ,marginBottom:5}}>
                <div style={{ flexGrow: 1 }}>CUSTOMER</div>
                <div style={{color:'blue',fontWeight:'bold'}}>{item.customer}</div>
              </div>
              <hr></hr>
              <div style={{ display: "flex" ,marginBottom:5}}>
                <div style={{ flexGrow: 1 }}>STYLE</div>
                <div style={{color:'blue',fontWeight:'bold'}}>{item.style}</div>
              </div>
              <hr></hr>
              <div style={{ display: "flex" ,marginBottom:5}}>
                <div style={{ flexGrow: 1 }}>ORDER NUMBER</div>
                <div style={{color:'blue',fontWeight:'bold'}}>{item.numberOrder}</div>
              </div>
              <hr></hr>
              <div style={{ display: "flex",marginBottom:5 }}>
                <div style={{ flexGrow: 1 }}>QTY</div>
                <div style={{color:'blue',fontWeight:'bold'}}>{item.qty}</div>
              </div>
              <hr></hr>
              <div style={{ display: "flex" ,marginBottom:5}}>
                <div style={{ flexGrow: 1 }}>OUT DATE</div>
                <div style={{color:'blue',fontWeight:'bold'}}>{item.dateOut}</div>
              </div>
              <hr></hr>
              <div style={{ display: "flex" ,marginBottom:5}}>
                <div style={{ flexGrow: 1 }}>NOTE</div>
                <div style={{color:'blue',fontWeight:'bold'}}>{item.note}</div>
              </div>
            </CardContent>
          </Card>
        ))
      )}
    </div>
  );
}
export default OrderMobilePage;
