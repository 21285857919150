import React from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { styled } from '@mui/material/styles';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor:"#DDD9C4",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function MachinesDesktopPage() {
  const [valueMachines, setValueMachines] = React.useState("");
  React.useEffect(() => {
    loadingTotalBeam();
    // ref.current.ownerDocument.body.scrollTop = 0;
  }, []);
  const loadingTotalBeam = () => {
    const dataBodyCustomer = {
      finish: "0",
    };
    fetch("https://hoangthongtelecom.com/ConnectDB/getMachines.php", {
      method: "POST",
      body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueMachines(result);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  return (
    <div style={{marginLeft:'185px',marginTop:10}}>
      {valueMachines === "" ? (
        <div></div>
      ) : (
            <TableContainer sx={{ maxHeight: "97vh" }}>
            <Table size='small' stickyHeader aria-label="sticky table">
              <TableHead style={{ height: "30px" }}>
                <TableRow>  
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#31869B",
                      color: "white",
                      fontWeight: "bold",
                      padding: 0,
                      width:150
                    }}
                  >
                    Machine
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#31869B",
                      color: "white",
                      fontWeight: "bold",
                      padding: 5,
                      width:150
                    }}
                  >
                    Style
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#31869B",
                      color: "white",
                      fontWeight: "bold",
                      padding: 0,
                      width: 100,
                    }}
                  >
                    Cloth lenght
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#31869B",
                      color: "white",
                      fontWeight: "bold",
                      padding: 5,
                    }}
                  >
                    Efficiency
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#31869B",
                      color: "white",
                      fontWeight: "bold",
                      // padding: 5,
                      height: 20,
                    }}
                  >
                    Remaining lenght
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#31869B",
                      color: "white",
                      fontWeight: "bold",
                      // padding: 5,
                      height: 20,
                      maxWidth:100
                    }}
                  >
                    Beam lenght
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#31869B",
                      color: "white",
                      fontWeight: "bold",
                      // padding: 5,
                      height: 20,
                    }}
                  >
                    Rpm
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#31869B",
                      color: "white",
                      fontWeight: "bold",
                      // padding: 5,
                      height: 20,
                    }}
                  >
                    Beam name
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: "#31869B",
                      color: "white",
                      fontWeight: "bold",
                      // padding: 5,
                      height: 20,
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {valueMachines === "" ? (
                  <></>
                ) : (
                    valueMachines.map((row) => (
                    <StyledTableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 1,
                        },
                      }}
                    >
                      <TableCell
                        align="center"
                        // component="th"
                        scope="row"
                        style={{
                          padding: 3,
                          fontSize: 15,
                        }}
                      >
                        {row.machinescode}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: 5,
                        }}
                      >
                        {row.machinesmahang}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          padding: 5,
                          fontSize: 15,
                        }}
                      >
                        {row.machinescloth}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          padding: 5,
                          fontSize: 15,
                        }}
                      >
                        {row.machinestoday}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          padding: 5,
                          fontSize: 15,
                        }}
                      >
                        {row.machinesRemaining}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          padding: 5,
                          fontSize: 15,
                        }}
                      >
                        {row.machinesBeam}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          padding: 5,
                          fontSize: 15,
                        }}
                      >
                        {row.machinesRpm}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          padding: 5,
                          fontSize: 15,
                        }}
                      >
                        {row.machine_BeamName}
                      </TableCell>
                      
                      <TableCell
                        align="center"
                        style={{
                          padding: 5,
                          fontSize: 15,
                          fontWeight:900,
                          color:row.machinesRuntime === "Run time" ? "green" : "red"
                        }}
                      >
                         {row.machinesRuntime === "Run time" ? "Run" : "Stop"}
                      </TableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
    </div>
  );
}
export default MachinesDesktopPage;
