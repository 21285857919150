import React, { useState } from "react";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Button from "@mui/material/Button";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#DDD9C4",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function SoiPage() {
  const [valueTotalSoi, setValueSoiBeam] = React.useState("");
  const [valueImport, setValueImport] = React.useState("");
  const [valueUse, setValueUse] = React.useState("");
  //const theme = useTheme();
  const [valueTab, setValueTab] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
    console.log(newValue);
  };

  const [selectedDateTimeFrom, setSelectedDateTimeFrom] = useState(
    dayjs().startOf("month")
  );
  const [selectedDateTimeTo, setSelectedDateTimeTo] = useState(
    dayjs(new Date())
  );
  React.useEffect(() => {
    loadingTotalSoi();
    loadingHistory();
    // ref.current.ownerDocument.body.scrollTop = 0;
  });
  const loadingTotalSoi = () => {
    fetch("https://hoangthongtelecom.com/KiemVaiLienA/getTotalQLSoi.php", {
      method: "POST",
      //   body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueSoiBeam(result);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  const loadingHistory = () => {
    const valueThoigianFrom = new Intl.DateTimeFormat("en-gb", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(selectedDateTimeFrom);
    const datePartFrom = valueThoigianFrom.split(",")[0].trim(); // Tách phần ngày
    const datePartsFrom = datePartFrom.split("/"); // Tách ngày, tháng, năm
    const convertDateTimeFrom = `${datePartsFrom[2]}-${datePartsFrom[1]}-${datePartsFrom[0]}`;

    const valueThoigianTo = new Intl.DateTimeFormat("en-gb", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(selectedDateTimeTo);
    const datePartTo = valueThoigianTo.split(",")[0].trim(); // Tách phần ngày
    const datePartsTo = datePartTo.split("/"); // Tách ngày, tháng, năm
    const convertDateTimeTo = `${datePartsTo[2]}-${datePartsTo[1]}-${datePartsTo[0]}`;
    const dataBodyCustomer = {
      fromNgay: convertDateTimeFrom,
      toNgay: convertDateTimeTo,
    };
    fetch("https://hoangthongtelecom.com/QLySoi/selectHistorySoiWeb.php", {
      method: "POST",
      body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        const qrIn = result.filter((item) => item.type === "Import");
        setValueImport(qrIn);
        console.log(qrIn);
        const qrOut = result.filter((item) => item.type === "Use");
        setValueUse(qrOut);
        console.log(qrOut);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  const loadSearchDataImport = () => {
    const valueThoigianFrom = new Intl.DateTimeFormat("en-gb", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(selectedDateTimeFrom);
    const datePartFrom = valueThoigianFrom.split(",")[0].trim(); // Tách phần ngày
    const datePartsFrom = datePartFrom.split("/"); // Tách ngày, tháng, năm
    const convertDateTimeFrom = `${datePartsFrom[2]}-${datePartsFrom[1]}-${datePartsFrom[0]}`;

    const valueThoigianTo = new Intl.DateTimeFormat("en-gb", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(selectedDateTimeTo);
    const datePartTo = valueThoigianTo.split(",")[0].trim(); // Tách phần ngày
    const datePartsTo = datePartTo.split("/"); // Tách ngày, tháng, năm
    const convertDateTimeTo = `${datePartsTo[2]}-${datePartsTo[1]}-${datePartsTo[0]}`;
    const dataBodyCustomer = {
      fromNgay: convertDateTimeFrom,
      toNgay: convertDateTimeTo,
    };
    fetch("https://hoangthongtelecom.com/QLySoi/selectHistorySoiWeb.php", {
      method: "POST",
      body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        const qrIn = result.filter((item) => item.type === "Import");
        setValueImport(qrIn);

      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
 const loadSearchDataUse = () => {
    const valueThoigianFrom = new Intl.DateTimeFormat("en-gb", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(selectedDateTimeFrom);
    const datePartFrom = valueThoigianFrom.split(",")[0].trim(); // Tách phần ngày
    const datePartsFrom = datePartFrom.split("/"); // Tách ngày, tháng, năm
    const convertDateTimeFrom = `${datePartsFrom[2]}-${datePartsFrom[1]}-${datePartsFrom[0]}`;

    const valueThoigianTo = new Intl.DateTimeFormat("en-gb", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(selectedDateTimeTo);
    const datePartTo = valueThoigianTo.split(",")[0].trim(); // Tách phần ngày
    const datePartsTo = datePartTo.split("/"); // Tách ngày, tháng, năm
    const convertDateTimeTo = `${datePartsTo[2]}-${datePartsTo[1]}-${datePartsTo[0]}`;
    const dataBodyCustomer = {
      fromNgay: convertDateTimeFrom,
      toNgay: convertDateTimeTo,
    };
    fetch("https://hoangthongtelecom.com/QLySoi/selectHistorySoiWeb.php", {
      method: "POST",
      body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        const qrOut = result.filter((item) => item.type === "Use");
        setValueUse(qrOut);
        console.log(qrOut);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  return (
    <div
      style={{
        marginLeft: "180px",
      }}
    >
      {valueTotalSoi === "" ? (
        <div></div>
      ) : (
        <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={valueTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="SUMMARY"
                    value="1"
                    style={{ minHeight: 40, fontWeight: 900 }}
                  />
                  <Tab
                    label="INPUT"
                    value="2"
                    style={{ minHeight: 40, fontWeight: 900 }}
                  />
                  <Tab
                    label="USE"
                    value="3"
                    style={{ minHeight: 40, fontWeight: 900 }}
                  />
                  <Tab
                    label="FINISH"
                    value="4"
                    style={{ minHeight: 40, fontWeight: 900 }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1" style={{ padding: 5 }}>
                <TableContainer sx={{ maxHeight: "90vh" }}>
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead style={{ height: "30px" }}>
                      <TableRow>
                        <TableCell
                          align="center"
                          rowSpan={2}
                          style={{
                            backgroundColor: "#31869B",
                            color: "white",
                            fontWeight: "bold",
                            padding: 0,
                            minWidth: 120,
                          }}
                        >
                          SUPPLIER
                        </TableCell>
                        <TableCell
                          align="center"
                          rowSpan={2}
                          style={{
                            backgroundColor: "#31869B",
                            color: "white",
                            fontWeight: "bold",
                            padding: 5,
                            minWidth: 180,
                          }}
                        >
                          ITEM
                        </TableCell>
                        <TableCell
                          align="center"
                          rowSpan={2}
                          style={{
                            backgroundColor: "#31869B",
                            color: "white",
                            fontWeight: "bold",
                            padding: 0,
                            minWidth: 220,
                          }}
                        >
                          LOT
                        </TableCell>
                        <TableCell
                          align="center"
                          rowSpan={2}
                          style={{
                            backgroundColor: "#31869B",
                            color: "white",
                            fontWeight: "bold",
                            padding: 5,
                            maxWidth: 60,
                          }}
                        >
                          KG/CONE
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={2}
                          style={{
                            backgroundColor: "#31869B",
                            color: "white",
                            fontWeight: "bold",
                            padding: 5,
                            height: 20,
                          }}
                        >
                          IMPORT
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={2}
                          style={{
                            backgroundColor: "#31869B",
                            color: "white",
                            fontWeight: "bold",
                            padding: 5,
                            height: 20,
                          }}
                        >
                          USE
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={2}
                          style={{
                            backgroundColor: "#31869B",
                            color: "white",
                            fontWeight: "bold",
                            padding: 5,
                            height: 20,
                          }}
                        >
                          INVENTORY
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={2}
                          style={{
                            backgroundColor: "#31869B",
                            color: "white",
                            fontWeight: "bold",
                            padding: 5,
                            height: 20,
                          }}
                        >
                          LOẠI SỢI
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          key={"In200"}
                          align={"center"}
                          style={{
                            top: 36,
                            maxWidth: 60,
                            backgroundColor: "#31869B",
                            padding: 5,
                            color: "white",
                          }}
                        >
                          CONE
                        </TableCell>
                        <TableCell
                          key={"In300"}
                          align={"center"}
                          style={{
                            top: 36,
                            maxWidth: 60,
                            backgroundColor: "#31869B",
                            padding: 5,
                            color: "white",
                          }}
                        >
                          KG
                        </TableCell>
                        <TableCell
                          key={"In400"}
                          align={"center"}
                          style={{
                            top: 36,
                            maxWidth: 60,
                            backgroundColor: "#31869B",
                            padding: 5,
                            color: "white",
                          }}
                        >
                          CONE
                        </TableCell>
                        <TableCell
                          key={"InTotal"}
                          align={"center"}
                          style={{
                            top: 36,
                            maxWidth: 60,
                            backgroundColor: "#31869B",
                            padding: 5,
                            color: "white",
                          }}
                        >
                          KG
                        </TableCell>
                        <TableCell
                          key={"Out200"}
                          align={"center"}
                          style={{
                            top: 36,
                            maxWidth: 60,
                            backgroundColor: "#31869B",
                            padding: 5,
                            color: "white",
                          }}
                        >
                          CONE
                        </TableCell>
                        <TableCell
                          key={"Out300"}
                          align={"center"}
                          style={{
                            top: 36,
                            maxWidth: 60,
                            backgroundColor: "#31869B",
                            padding: 5,
                            color: "white",
                          }}
                        >
                          KG
                        </TableCell>
                        <TableCell
                          key={"Out300"}
                          align={"center"}
                          style={{
                            top: 36,
                            maxWidth: 60,
                            backgroundColor: "#31869B",
                            padding: 5,
                            color: "white",
                          }}
                        >
                          SỢI DỌC
                        </TableCell>
                        <TableCell
                          key={"Out300"}
                          align={"center"}
                          style={{
                            top: 36,
                            maxWidth: 60,
                            backgroundColor: "#31869B",
                            padding: 5,
                            color: "white",
                          }}
                        >
                          SỢI NGANG
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {valueTotalSoi === "" ? (
                        <></>
                      ) : (
                        valueTotalSoi.map((row) => (
                          <StyledTableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 1,
                              },
                            }}
                          >
                            <TableCell
                              align="left"
                              // component="th"
                              scope="row"
                              style={{
                                padding: 3,
                                fontSize: 15,
                              }}
                            >
                              {row.Supplier}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: 5,
                              }}
                            >
                              {row.Item}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: 5,
                                fontSize: 15,
                              }}
                            >
                              {row.lot}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: 5,
                                fontSize: 15,
                              }}
                            >
                              {row.KgCoi}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                padding: 5,
                                fontSize: 15,
                              }}
                            >
                              {row.totalImportCone}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                padding: 5,
                                fontSize: 15,
                              }}
                            >
                              {row.totalImportKg}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                padding: 5,
                                fontSize: 15,
                              }}
                            >
                              {row.totalUseCone}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                padding: 5,
                                fontSize: 15,
                              }}
                            >
                              {row.totalUseKg}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                padding: 5,
                                fontSize: 15,
                              }}
                            >
                              {row.totalInvenCone}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                padding: 5,
                                fontSize: 15,
                              }}
                            >
                              {row.totalInvenKg}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                padding: 5,
                                fontSize: 15,
                              }}
                            >
                              {row.Warp}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                padding: 5,
                                fontSize: 15,
                              }}
                            >
                              {row.weft}
                            </TableCell>
                          </StyledTableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value="2" style={{ padding: 5 }}>
                <div>
                  <div style={{ display: "flex", marginBottom: 10 }}>
                    <div>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"en-gb"}
                      >
                        <DatePicker
                          format="DD/MM/YYYY"
                          value={selectedDateTimeFrom}
                          onChange={(newValue) => {
                            setSelectedDateTimeFrom(newValue);
                          }}
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div style={{ width: 10 }}></div>
                    <div>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"en-gb"}
                      >
                        <DatePicker
                          format="DD/MM/YYYY"
                          value={selectedDateTimeTo}
                          onChange={(newValue) => {
                            setSelectedDateTimeTo(newValue);
                          }}
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div style={{ width: 10 }}></div>
                    <div>
                      <Button onClick={loadSearchDataImport} variant="contained">
                        Search
                      </Button>
                    </div>
                  </div>
                  <TableContainer sx={{ maxHeight: "82vh" }}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableHead style={{ height: "30px" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            // rowSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 0,
                              minWidth: 120,
                            }}
                          >
                            IMPORT DATE
                          </TableCell>
                          <TableCell
                            align="center"
                            rowSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 5,
                              minWidth: 180,
                            }}
                          >
                            SUPPLIER
                          </TableCell>
                          <TableCell
                            align="center"
                            //rowSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 0,
                              minWidth: 220,
                            }}
                          >
                            ITEM
                          </TableCell>
                          <TableCell
                            align="center"
                            // rowSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 5,
                              maxWidth: 60,
                            }}
                          >
                            LOT
                          </TableCell>
                          <TableCell
                            align="center"
                            //colSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 5,
                              height: 20,
                            }}
                          >
                            KG/CONE
                          </TableCell>
                          <TableCell
                            align="center"
                            // colSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 5,
                              height: 20,
                            }}
                          >
                            CONE
                          </TableCell>
                          <TableCell
                            align="center"
                            //colSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 5,
                              height: 20,
                            }}
                          >
                            KG
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {valueImport === "" ? (
                          <></>
                        ) : (
                          valueImport.map((row) => (
                            <StyledTableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 1,
                                },
                              }}
                            >
                              <TableCell
                                align="left"
                                // component="th"
                                scope="row"
                                style={{
                                  padding: 3,
                                  fontSize: 15,
                                }}
                              >
                                {row.ngay}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  padding: 5,
                                }}
                              >
                                {row.Supplier}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  padding: 5,
                                  fontSize: 15,
                                }}
                              >
                                {row.Item}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  padding: 5,
                                  fontSize: 15,
                                }}
                              >
                                {row.Lot}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  padding: 5,
                                  fontSize: 15,
                                }}
                              >
                                {row.KgCoi}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  padding: 5,
                                  fontSize: 15,
                                }}
                              >
                                {row.Cone}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  padding: 5,
                                  fontSize: 15,
                                }}
                              >
                                {row.Kg}
                              </TableCell>
                            </StyledTableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </TabPanel>
              <TabPanel value="3" style={{ padding: 5 }}>
                <div>
                <div style={{ display: "flex", marginBottom: 10 }}>
                    <div>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"en-gb"}
                      >
                        <DatePicker
                          format="DD/MM/YYYY"
                          value={selectedDateTimeFrom}
                          onChange={(newValue) => {
                            setSelectedDateTimeFrom(newValue);
                          }}
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div style={{ width: 10 }}></div>
                    <div>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"en-gb"}
                      >
                        <DatePicker
                          format="DD/MM/YYYY"
                          value={selectedDateTimeTo}
                          onChange={(newValue) => {
                            setSelectedDateTimeTo(newValue);
                          }}
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div style={{ width: 10 }}></div>
                    <div>
                      <Button onClick={loadSearchDataUse} variant="contained">
                        Search
                      </Button>
                    </div>
                  </div>
                </div>
              <TableContainer sx={{ maxHeight: "82vh" }}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableHead style={{ height: "30px" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            // rowSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 0,
                              minWidth: 120,
                            }}
                          >
                            IMPORT DATE
                          </TableCell>
                          <TableCell
                            align="center"
                            rowSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 5,
                              minWidth: 180,
                            }}
                          >
                            SUPPLIER
                          </TableCell>
                          <TableCell
                            align="center"
                            //rowSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 0,
                              minWidth: 220,
                            }}
                          >
                            ITEM
                          </TableCell>
                          <TableCell
                            align="center"
                            // rowSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 5,
                              maxWidth: 60,
                            }}
                          >
                            LOT
                          </TableCell>
                          <TableCell
                            align="center"
                            //colSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 5,
                              height: 20,
                            }}
                          >
                            KG/CONE
                          </TableCell>
                          <TableCell
                            align="center"
                            // colSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 5,
                              height: 20,
                            }}
                          >
                            CONE
                          </TableCell>
                          <TableCell
                            align="center"
                            //colSpan={2}
                            style={{
                              backgroundColor: "#31869B",
                              color: "white",
                              fontWeight: "bold",
                              padding: 5,
                              height: 20,
                            }}
                          >
                            KG
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {valueUse === "" ? (
                          <></>
                        ) : (
                          valueUse.map((row) => (
                            <StyledTableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 1,
                                },
                              }}
                            >
                              <TableCell
                                align="left"
                                // component="th"
                                scope="row"
                                style={{
                                  padding: 3,
                                  fontSize: 15,
                                }}
                              >
                                {row.ngay}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  padding: 5,
                                }}
                              >
                                {row.Supplier}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  padding: 5,
                                  fontSize: 15,
                                }}
                              >
                                {row.Item}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  padding: 5,
                                  fontSize: 15,
                                }}
                              >
                                {row.Lot}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  padding: 5,
                                  fontSize: 15,
                                }}
                              >
                                {row.KgCoi}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  padding: 5,
                                  fontSize: 15,
                                }}
                              >
                                {row.Cone}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  padding: 5,
                                  fontSize: 15,
                                }}
                              >
                                {row.Kg}
                              </TableCell>
                            </StyledTableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
              </TabPanel>
            </TabContext>
          </Box>
         
        </Box>
      )}
    </div>
  );
}
export default SoiPage;
