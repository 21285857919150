import React from "react";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";

function SoiMobilePage() {
  const [valueTotalSoi, setValueSoiBeam] = React.useState("");
  const [valueTotalSoiShow, setValueSoiBeamShow] = React.useState("");
  React.useEffect(() => {
   loadingTotalSoi();
    // ref.current.ownerDocument.body.scrollTop = 0;
  }, []);
  const loadingTotalSoi = () => {
    fetch("https://hoangthongtelecom.com/KiemVaiLienA/getTotalQLSoi.php", {
      method: "POST",
      //   body: JSON.stringify(dataBodyCustomer),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setValueSoiBeam(result);
        setValueSoiBeamShow(result);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  const handleInputChange = (event) => {
    const qr = valueTotalSoi.filter((item) =>
      item.note.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setValueSoiBeamShow(qr);
  };
  return (
    <div
      style={{
        backgroundColor: "gray",
        padding: 5,
      }}
    >
      <div style={{ marginBottom: "5px" }}>
        <TextField
          id="outlined-basic"
          style={{
            backgroundColor: "white",
            width: "100%",
            borderRadius: "10px",
          }}
          placeholder="Enter Item or Lot to search"
          size="small"
          variant="outlined"
          onChange={handleInputChange}
        />
      </div>
      {valueTotalSoiShow === "" ? (
        <div>
          <Box
            sx={{
              display: "flex",
              backgroundColor: "white",
              width: "100%",
              height: "90vh",
              // paddingTop:'20%',
              // paddingLeft:'30%',
              textAlign: "center",
              alignItems: "center",
              justifyItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
            <div>Loading ....</div>
          </Box>
        </div>
      ) : (
        valueTotalSoiShow.map((item) => (
          <Card sx={{ minWidth: 275, marginBottom: "5px", padding: 0 }}>
            <CardContent style={{ margin: 0, padding: 10 }}>
              <div style={{ display: "flex", marginBottom: 5 }}>
                <div style={{ flexGrow: 1 }}>Supplier</div>
                <div style={{ color: "blue", fontWeight: "bold" }}>
                  {item.Supplier}
                </div>
              </div>
              <hr></hr>
              <div style={{ display: "flex", marginBottom: 5 }}>
                <div style={{ flexGrow: 1 }}>Item</div>
                <div style={{ color: "blue", fontWeight: "bold" }}>
                  {item.Item}
                </div>
              </div>
              <hr></hr>
              <div style={{ display: "flex", marginBottom: 5 }}>
                <div style={{ flexGrow: 1 }}>Lot</div>
                <div style={{ color: "blue", fontWeight: "bold" }}>
                  {item.lot}
                </div>
              </div>
              <hr></hr>
              <div style={{ display: "flex", marginBottom: 5 }}>
                <div style={{ flexGrow: 1 }}>Kg/Cone</div>
                <div style={{ color: "blue", fontWeight: "bold" }}>
                  {item.KgCoi}
                </div>
              </div>
              <hr></hr>
              <div style={{ display: "flex", marginBottom: 5 }}>
                <div style={{ flexGrow: 1 }}>Inventory (Cone)</div>
                <div style={{ color: "blue", fontWeight: "bold" }}>
                  {item.totalInvenCone}
                </div>
              </div>
              <hr></hr>
              <div style={{ display: "flex", marginBottom: 5 }}>
                <div style={{ flexGrow: 1 }}>Inventory (Kg)</div>
                <div style={{ color: "blue", fontWeight: "bold" }}>
                  {item.totalInvenKg}
                </div>
              </div>
            </CardContent>
          </Card>
        ))
      )}
    </div>
  );
}
export default SoiMobilePage;
